import React, { useState } from 'react';
import Axios from 'axios';
import "./main.css"
import "./flaticon.css"
import { Link } from "react-router-dom";

const Main = () => {

    const url = "https://auraairline.com/aura_airlines/CustomerInquiryDetails"

    const [data, setData] = useState({
        title: '',
        first_name: '',
        last_name: '',
        contact_number: '',
        email_address: '',
        flight_info: '',
        departure_city: '',
        destination_city: '',
        depature_date: '',
        depature_datetime: '',
        passanger_count: '',
        special_req: ''
    });

    function submit(e){
        
        e.preventDefault();

        Axios.post(url,{
            title:data.title,
            first_name:data.first_name,
            last_name:data.last_name,
            contact_number:data.contact_number,
            email_address:data.email_address,
            flight_info:data.flight_info,
            departure_city:data.departure_city,
            destination_city:data.destination_city,
            depature_date:data.depature_date,
            depature_datetime:data.depature_datetime,
            passanger_count:data.passanger_count,
            special_req:data.special_req
        })
            .then(res=>{
                const responseData = res.data;
                console.log(responseData);

                if (responseData.status === 'SUCCESS') {
                    Swal.fire({
                        title: 'Success',
                        text: responseData.message,
                    }).then(() => {
                        // Reset the form
                        setData({
                            title:"",
                            first_name:"",
                            last_name:"",
                            contact_number:"",
                            email_address:"",
                            flight_info:"",
                            departure_city:"",
                            destination_city:"",
                            depature_date:"",
                            depature_datetime:"",
                            passanger_count:"",
                            special_req:""
                        });
                    });
                    
                } else {
                    // Handle other response statuses or errors
                    Swal.fire({
                        title: 'Error',
                        text: 'Reservation request failed. Please try again.',
                    });
                }

            })
            .catch(error => {
                console.error("An error occurred:", error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred while processing your request. Please try again later.',
                });
            });
                
        setData({
            title:"",
            first_name:"",
            last_name:"",
            contact_number:"",
            email_address:"",
            flight_info:"",
            departure_city:"",
            destination_city:"",
            depature_date:"",
            depature_datetime:"",
            passanger_count:"",
            special_req:""
          });

    }

    function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        /*console.log(newdata)*/

    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behaviour: "smooth"});
    }

    const handleClick = (description) => {
 
            Swal.fire({
                title: 'Service Description',
                text: description,
                confirmButtonText: 'OK'
            });
 
      
    };

    const [isDisabled, setIsDisabled] = useState(true);

  return (
    <>
    
      <div class="container-fluid p-0 mb-0">
          <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">

                <div class="carousel-item active">
                    <img class="w-100 sty" src="carousel-7.jpg" alt="carosel_1" />
                    <div class="carousel-caption">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-7 pt-0">
                                    <h1 class="display-4 text-white mb-3 animated slideInDown">Skybound Journeys</h1>
                                    <h5 class="text-white text-uppercase mb-5">With Aura Airlines</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="carousel-item">
                    <img class="w-100 sty" src="carousel-10.jpg" alt="carosel_2" />
                    <div class="carousel-caption">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-7 pt-0">
                                    <h1 class="display-4 text-white text-uppercase">SKYDIVE</h1>
                                    <h1 class="display-4 text-white text-uppercase">SRI LANKA</h1>
                                    <h4 class="display-6 text-white mb-3 animated slideInDown">Are You Ready To Elevate Your Adventures Over Paradise?</h4>
                                    <Link class="btn btn-primary py-2 px-3 animated slideInDown" to='https://skydivesrilanka.com/' target="_blank">
                                        Get Early Access
                                        <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                            <i class="fa fa-arrow-right"></i>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                  {/* <div class="carousel-item active">
                      <img class="w-100 sty" src="carousel-2.jpg" alt="carosel_1" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-center">
                                  <div class="col-lg-7 pt-5">
                                      <h5 class="text-white text-uppercase">Welcome to the Aura Airlines</h5>
                                      <h1 class="display-4 text-white mb-3 animated slideInDown">Life Is Short And The World Is Wide!</h1>
                                      <p class="fs-5 text-white-50 mb-5 animated slideInDown home_para">Curly Airline proudly raises the bar and exceeds the standard for luxury and corporate private plane services. We pride ourselves on offering a professional service.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="carousel-item">
                      <img class="w-100 sty" src="carousel-0.jpg" alt="carosel_2" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-center">
                                  <div class="col-lg-7 pt-5">
                                      <h5 class="text-white text-uppercase">Welcome to the Aura Aura Airlines</h5>
                                      <h1 class="display-4 text-white mb-3 animated slideInDown">Best Airline To Fly To Serve</h1>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="carousel-item">
                      <img class="w-100 sty" src="carousel-3.jpg" alt="carosel_3" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-center">
                                  <div class="col-lg-7 pt-5">
                                      <h5 class="text-white text-uppercase">Welcome to the Aura Aura Airlines</h5>
                                      <h1 class="display-4 text-white mb-3 animated slideInDown">Experience Your Travel With Aura Airlines.</h1>
                                      <p class="fs-5 text-white-50 mb-5 animated slideInDown home_para">Whether you're traveling for business or pleasure, a private plane charter provides the ultimate travel experience.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="carousel-item">
                      <img class="w-100 sty" src="carousel-1.jpg" alt="carosel_4" />
                      <div class="carousel-caption">
                          <div class="container">
                              <div class="row justify-content-center">
                                  <div class="col-lg-7 pt-5">
                                      <h5 class="text-white text-uppercase">Welcome to the Aura Aura Airlines</h5>
                                      <h1 class="display-4 text-white mb-3 animated slideInDown">ARE YOU READY FOR TAKE-OFF?</h1>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> */}

              </div>
              
              <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                  data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
                  data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
              </button>
          </div>

        <div class="container-fluid banner mb-4" id="booknowform">
            <div class="container">
                <div class="row gx-0">

                    <div class="col-lg-12 wow zoomIn" data-wow-delay="0.3s">
                        <div class="glassmorphism-bg d-flex flex-column p-3" style={{ Height: '300' }}>
                            <h3 class="text-white mb-3">Reserve Your Flight Today</h3>
                            <form id="bookForm" onSubmit={(e) => submit(e)}>
                                <div class="row g-3">
                                    <div className="col-12 col-sm-4">
                                        <select onChange={(e) => handle(e)} id="title" value={data.title} name="title" className="form-select border-0" style={{ height: '50px' }} required>
                                            <option value="" hidden="true">Title: (Mr. Mrs. Ms.)</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Ms">Ms</option>
                                        </select>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <input type="text" class="form-control border-0" onChange={(e) => handle(e)} id="first_name" value={data.first_name} placeholder="First Name" style={{ height: '55px' }} required />
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <input type="text" class="form-control border-0" onChange={(e) => handle(e)} id="last_name" value={data.last_name} placeholder="Last Name" style={{ height: '55px' }} required />
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <input type="text" class="form-control border-0" onChange={(e) => handle(e)} id="contact_number" value={data.contact_number} placeholder="Contact Number" style={{ height: '55px' }} required />
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <input type="text" class="form-control border-0" onChange={(e) => handle(e)} id="email_address" value={data.email_address} placeholder="Email Address" style={{ height: '55px' }} required />
                                    </div>


                                    <div className="col-12 col-sm-4">
                                        <select onChange={(e) => handle(e)} id="flight_info" value={data.flight_info} name="flight_info" className="form-select border-0" style={{ height: '50px' }} required>
                                            <option value="" hidden="true">Flight Information</option>
                                            <option value="OneWay">One Way</option>
                                            <option value="RoundTrip">Round Trip</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <select onChange={(e) => handle(e)} id="departure_city" value={data.departure_city} name="departure_city" className="form-select border-0" style={{ height: '50px' }} required>
                                            <option value="" hidden="true">Departure</option>
                                            <option value="Galle">Galle</option>
                                            <option value="Tangalle">Tangalle</option>
                                            <option value="Koggala">Koggala</option>
                                            <option value="Yala">Yala</option>
                                            <option value="Wilpattu">Wilpattu</option>
                                            <option value="Udawalawe">Udawalawe</option>
                                            <option value="Trincomalee">Trincomalee</option>
                                            <option value="Batticaloa">Batticaloa</option>
                                            <option value="Arugam Bay">Arugam Bay</option>
                                            <option value="Jaffna">Jaffna</option>
                                            <option value="Vavuniya">Vavuniya</option>
                                            <option value="NuwraEliya">NuwraEliya</option>
                                            <option value="Kandy">Kandy</option>
                                            <option value="Norwood">Norwood</option>
                                            <option value="Sigiriya">Sigiriya</option>
                                            <option value="Anuradhapura">Anuradhapura</option>
                                            <option value="Kataragama">Kataragama</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <select onChange={(e) => handle(e)} id="destination_city" value={data.destination_city} name="destination_city" className="form-select border-0" style={{ height: '50px' }} required>
                                            <option value="" hidden="true">Destination</option>
                                            <option value="Galle">Galle</option>
                                            <option value="Tangalle">Tangalle</option>
                                            <option value="Koggala">Koggala</option>
                                            <option value="Yala">Yala</option>
                                            <option value="Wilpattu">Wilpattu</option>
                                            <option value="Udawalawe">Udawalawe</option>
                                            <option value="Trincomalee">Trincomalee</option>
                                            <option value="Batticaloa">Batticaloa</option>
                                            <option value="Arugam Bay">Arugam Bay</option>
                                            <option value="Jaffna">Jaffna</option>
                                            <option value="Vavuniya">Vavuniya</option>
                                            <option value="NuwraEliya">NuwraEliya</option>
                                            <option value="Kandy">Kandy</option>
                                            <option value="Norwood">Norwood</option>
                                            <option value="Sigiriya">Sigiriya</option>
                                            <option value="Anuradhapura">Anuradhapura</option>
                                            <option value="Kataragama">Kataragama</option>
                                            <option value="Koggala">Koggala</option>
                                            <option value="Mattala">Mattala</option>
                                            <option value="Mattala/Weerawila">Mattala/Weerawila</option>
                                            <option value="Anuradhapura/Palavi">Anuradhapura/Palavi</option>
                                            <option value="Mattala/Ratnapura">Mattala/Ratnapura</option>
                                            <option value="Minneriya">Minneriya</option>
                                            <option value="Ampara/Minneriya">Ampara/Minneriya</option>
                                            <option value="Ampara">Ampara</option>
                                            <option value="Palali">Palali</option>
                                            <option value="Diyatalawa">Diyatalawa</option>
                                            <option value="Kegalle/Digana">Kegalle/Digana</option>
                                            <option value="Ginigatthena">Ginigatthena</option>
                                            <option value="Minneriya/Dambulla">Minneriya/Dambulla</option>
                                            <option value="Vavuniya/Sigiriya">Vavuniya/Sigiriya</option>
                                            <option value="Mattala/Weerawila">Mattala/Weerawila</option>
                                        </select>
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <input type="date" class="form-control border-0" onChange={(e) => handle(e)} id="depature_date" value={data.depature_date} placeholder="Date of Departure" style={{ height: '55px' }} required />
                                    </div>

                                    <div class="col-12 col-sm-4">
                                        <input type="time" class="form-control border-0" onChange={(e) => handle(e)} id="depature_datetime" value={data.depature_datetime} placeholder="Time of Flight: (hh/mm)" style={{ height: '55px' }} required />
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <select onChange={(e) => handle(e)} id="passanger_count" value={data.passanger_count} name="passanger_count" className="form-select border-0" style={{ height: '50px' }} required>
                                            <option value="" hidden="true">Select Passanger Count</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>

                                    <div class="col-12">
                                        <textarea class="form-control border-0" onChange={(e) => handle(e)} id="special_req" value={data.special_req} placeholder="Special Request"></textarea>
                                    </div>
                               
                                    <div className="col-6">
                                        <label className="form-check-label ms-3 py-3" htmlFor="terms" style={{ fontSize: '16px', fontFamily: 'Arial, sans-serif', color: '#3e3e3e', letterSpacing: '0.5px' }}>
                                            By clicking "Book Now", you agree to the
                                            <Link onClick={scrollToTop} to="/Conditions" style={{ color: '#1c61f7' }}> Terms </Link>
                                            and acknowledge the
                                            <Link onClick={scrollToTop} to="/Policy" style={{ color: '#1c61f7' }}> Privacy Policy</Link>
                                        </label>
                                    </div>

                                    <div className="col-6">
                                        <div className="col-12 d-flex justify-content-end ">
                                            <button className="btn btn-primary w-75 py-3" type="submit">Book Now</button>
                                        </div>
                                    </div>

                                    {/* <label className="form-check-label" htmlFor="terms" style={{ fontSize: '16px', fontWeight: 'bold', color: '#3e3e3e', letterSpacing: '1px' }}>
                                        By clicking "Book Now", you agree to the
                                        <Link onClick={scrollToTop} to="/Conditions" style={{ color: '#1c61f7' }}> Terms </Link>
                                        and acknowledge the
                                        <Link onClick={scrollToTop} to="/Policy" style={{ color: '#1c61f7' }}> Privacy Policy</Link>
                                    </label> */}

                                    {/* <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="terms" name="terms" required />
                                            <label className="form-check-label" htmlFor="terms" style={{ fontSize: '16px', fontWeight: 'bold', color: '#6c6c6c' }}>
                                                I agree to the terms and conditions
                                            </label>
                                        </div>
                                    </div> */}

                                    {/* <div class="col-12 d-flex justify-content-between">
                                        <button class="btn btn-primary w-50 py-3" type="submit">Book Now</button>

                                        <div class="ms-3"></div>
                                            <Link onClick={scrollToTop} to="/Conditions" class="btn btn-secondary w-50 py-3" type="button">Terms & Conditions</Link>
                                    </div> */}

                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <section class="section_para nopad cac text-center wow zoomIn" data-wow-delay="0.1s">
            {/* <h4 class="main_para">Aura Airlines is a pioneering force in the Sri Lankan airline industry, having 
                                    been established in 2018. Our core mission is to elevate airline services, with 
                                    an unwavering commitment to benefiting the entire community.</h4>

            <h4 class="main_para">As a versatile private and business airline, we offer an exclusive platform where 
                                    our valued customers can access a wide range of services designed to cater to 
                                    their diverse needs. Our comprehensive offerings encompass leisure, 
                                    corporate, and VIP transport solutions, extending our top-notch services to 
                                    both the domestic and international markets.</h4> */}

            {/* <h4 class="main_para">At Aura Airlines, we're not just a company; we're a commitment to excellence 
                                    in the skies. Our dedicated team strives to provide unparalleled experiences 
                                    for all passengers, ensuring that every journey with us is a seamless and 
                                    enjoyable one. Join us in experiencing the difference that Aura Airlines brings 
                                    to the world of aviation.</h4> */}

        </section>

      </div>

      {/* About Us */}

      {/* <div class="container-xxl py-3">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-6" data-wow-delay="0.1s">
                    <div class="position-relative overflow-hidden h-100" style={{maxHeight: '400px'}}>
                        <img class="position-absolute w-100 h-100 pt-5 pe-5 animated wow fadeInLeft" data-wow-delay="0.3s" src="img/about2.jpg" alt="" style={{objectFit: 'cover'}} />
                        <img class="position-absolute top-0 end-0 bg-white ps-2 pb-2 wow animated fadeInUp" data-wow-delay="0.3s" src="img/about5.jpg" alt="" style={{width: '200px',height: '200px'}} />
                    </div>
                    <br />
                    <p class="mb-5 wow animated zoomIn" data-wow-delay="0.6s" style={{textAlign: 'center'}}>Curly Airline proudly raises the bar and exceeds the standard for luxury and corporate private jet charter services. We pride ourselves on offering a professional and personalised service.</p>
                </div>
                <div class="col-lg-6" data-wow-delay="0.5s">
                    <div class="h-100">
                        <div class="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">About Us</div>
                        <h1 class="display-6 mb-2"><span style={{color: '#1A76D1'}}>Welcome to The Aura Airlines</span> Let Your Dreams Take A Flight</h1>
                        <div class="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">

                            <p class="text-dark mb-2 wow animated slideInLeft" data-wow-delay="0.3s">Aura Airlines is a pioneering force in the Sri Lankan airline industry, having 
                                                        been established in 2018. Our core mission is to elevate airline services, with 
                                                        an unwavering commitment to benefiting the entire community.</p>

                            <p class="text-dark mb-2 wow animated slideInRight" data-wow-delay="0.3s">As a versatile private and business airline, we offer an exclusive platform where 
                                                        our valued customers can access a wide range of services designed to cater to 
                                                        their diverse needs. Our comprehensive offerings encompass leisure, 
                                                        corporate, and VIP transport solutions, extending our top-notch services to 
                                                        both the domestic and international markets.</p>

                                                        <br /><br />

                        </div>
                       
                        <Link onClick={scrollToTop} class="btn btn-primary py-2 px-3 me-3" to="/about">
                            About More
                            <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                <i class="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                        <Link onClick={scrollToTop} class="btn btn-outline-primary py-2 px-3" to="/Inquire">
                            Contact Us
                            <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                <i class="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

    {/* <section id='booknowform'></section> */}

    {/* Our Destination */}

    <div class="favourite-place place-padding py-3 wow animated slideInUp" data-wow-delay="0.6s">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">
                          <div class="section_title_div text-center position-relative pb-0 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                              {/* <h5 class="fw-bold text-primary text-uppercase">FEATURED TOURS DESTINATIONS</h5>
                              <h1 class="mb-3">It's a Big World Out There, Go Explore</h1> */}
                              <h1 class="mb-3">Our Destinations</h1>
                              {/* <Link onClick={scrollToTop} class="btn btn-primary py-2 px-3 me-3" to="/Destination">
                                  View All Destinations
                                  <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                      <i class="fa fa-arrow-right"></i>
                                  </div>
                              </Link> */}
                          </div>
                      </div>
                  </div>

                  <div class="container-fluid">
                      <div class="container">

                          <div class="row g-3">

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="img/project-9.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">North and North East</h5>
                                                  <p>Sri Lanka, nestled in the heart of the Indian Ocean, boasts a treasure trove of tourist
                                                      destinations, each offering unique experiences. The Northern and Northeastern regions,
                                                      often overlooked by tourists due to their tumultuous past, are now emerging as
                                                      captivating destinations, blending history, culture, and natural beauty in equal measure.</p>
                                                  <Link class="text-primary text-uppercase" onClick={scrollToTop} to="/Destination#destination1">Read More<i class="bi bi-chevron-right"></i></Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">Heritage</h5>
                                                  <p>Experience the unparalleled beauty and cultural richness of Sri Lanka's top destinations.
                                                      Sigiriya's Lion Rock, a UNESCO World Heritage Site, offers breathtaking views and
                                                      historical intrigue. Kandy's Temple of the Sacred Tooth Relic, a spiritual epicenter,
                                                      captivates with its sacred Buddhist artifact and vibrant Esala Perahera festival. Dambulla
                                                      Cave Temple enchants with its ancient murals and serene Buddha statues.</p>
                                                  <Link class="text-primary text-uppercase" to="/Destination#heritage">Read More<i class="bi bi-chevron-right"></i></Link>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="destination/dest_04.png" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="destination/dest_03.png" style={{ objectFit: 'cover' }} />
                                          </div>
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">Wildlife</h5>
                                                  <p>Sri Lanka, often referred to as the "Pearl of the Indian Ocean," is renowned for its stunning
                                                      landscapes, rich cultural heritage, and abundant wildlife. Experience the unparalleled
                                                      biodiversity of Sri Lanka's wildlife destinations. Yala National Park, renowned for its dense
                                                      leopard population, offers thrilling safaris amidst diverse ecosystems and historical
                                                      sites. Horton Plains National Park, a UNESCO World Heritage Site and the breathtaking World's End cliff.</p>
                                                      <Link class="text-primary text-uppercase" to="/Destination#wildlife">Read More<i class="bi bi-chevron-right"></i></Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">South Coast</h5>
                                                  <p>The enchanting South Coast of Sri Lanka beckons travellers with its pristine beaches,
                                                      vibrant culture, and rich history, stretching from the bustling city of Colombo to the
                                                      serene shores of Tangalle. Colombo, the commercial capital, blends modernity and
                                                      tradition with its historic Fort district, lively Pettah Market, and cultural landmarks like the
                                                      National Museum. Bentota, a tropical paradise, along with tranquil river cruises through mangrove forests.</p>
                                                    <Link class="text-primary text-uppercase" to="/Destination#south">Read More<i class="bi bi-chevron-right"></i></Link>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="destination/project-8.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="destination/dest_08.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">Hill Country</h5>
                                                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptas quia neque omnis voluptatibus facilis soluta, ut quisquam labore aperiam porro blanditiis rem nulla a animi.</p>
                                                  <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-3 mt-4 mt-lg-0">
                              </div>

                              <div class="col-lg-9">
                                  <div class="blog-items" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                      <div class="row g-0 bg-light overflow-hidden" >
                                          <div class="col-12 col-sm-7 h-100 d-flex flex-column justify-content-center">
                                              <div class="p-4">
                                                  <h5 class="text-uppercase mb-3">East Coast</h5>
                                                  <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptas quia neque omnis voluptatibus facilis soluta, ut quisquam labore aperiam porro blanditiis rem nulla a animi.</p>
                                                  <a class="text-primary text-uppercase" href="">Read More<i class="bi bi-chevron-right"></i></a>
                                              </div>
                                          </div>
                                          <div class="col-12 col-sm-5 h-100">
                                              <img class="img-fluid h-100" src="destination/dest_07.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>

                      </div>
                  </div>

                  {/* <div class="row">

                      <section id="emp_section_one">
                          <div class="container-fluid py-3">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInLeft" data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4">
                                              <h1 class="mb-3">North and North East</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis qui reiciendis, mollitia velit fuga illum voluptas corrupti ex nam.</p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline5.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section id="emp_section_two">
                          <div class="container-fluid py-3">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline8.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInLeft" style={{ zIndex: '1' }} data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4 ">
                                              <h1 class="mb-3">Heritage</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci architecto quasi suscipit ducimus cumque! Ut, consequatur! Aperiam </p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section id="emp_section_one">
                          <div class="container-fluid py-3">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInLeft" style={{ zIndex: '1' }} data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4 ">
                                              <h1 class="mb-3">Wildlife</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis qui reiciendis, mollitia velit fuga illum voluptas corrupti ex nam odit at sed aut, a consequatur.</p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInRight" style={{zIndex: '0' }} data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline5.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section id="emp_section_two">
                          <div class="container-fluid py-3">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline11.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInLeft" style={{ zIndex: '1' }} data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4 ">
                                              <h1 class="mb-3">South Coast</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci architecto quasi suscipit ducimus cumque! Ut, consequatur! Aperiam </p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section id="emp_section_one">
                          <div class="container-fluid py-1">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInLeft" style={{ zIndex: '1' }} data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4 ">
                                              <h1 class="mb-3">Hill Country</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis qui reiciendis, mollitia velit fuga illum voluptas corrupti ex nam odit at sed aut, a consequatur.</p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInRight" style={{zIndex: '0' }} data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline5.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                      <section id="emp_section_two">
                          <div class="container-fluid py-1">
                              <div class="container">
                                  <div class="row">
                                      <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.3s">
                                          <div class="position-relative h-100">
                                              <img class="position-absolute w-100 h-auto" src="Airlines/auraairline6.jpg" style={{ objectFit: 'cover' }} />
                                          </div>
                                      </div>
                                      <div class="col-lg-6 wow fadeInLeft" style={{ zIndex: '1' }} data-wow-delay="0.3s">
                                          <div class="about-text_right bg-white p-4 ">
                                              <h1 class="mb-3">EAST</h1>
                                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci architecto quasi suscipit ducimus cumque! Ut, consequatur! Aperiam </p>
                                              <a class="text-uppercase p-2" href="" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                              <div class="row mb-4 wow fadeInUp py-2" data-wow-delay="0.6s">
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline2.jpg" alt="" />
                                                  </div>
                                                  <div class="col-6">
                                                      <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>

                  </div> */}
              </div>
          </div>

          {/* Our Destination */}

          <div class="categories-area section-padding30 py-3 wow animated slideInUp" data-wow-delay="0.6s">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-12">
                          <div class="section-tittle text-center position-relative pb-3 mb-0 mx-auto" style={{ maxWidth: '600px' }}>
                              {/* <h5 class="fw-bold text-primary text-uppercase">What We Offer</h5> */} 
                              <h1 class="mb-0">What We Offer</h1>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-1 text-center mb-30" onClick={() => handleClick('Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit quod modi sapiente earum suscipit maiores inventore nesciunt ipsa numquam fugiat, natus provident repellat architecto eveniet!')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Corporate</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-2 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi, exercitationem.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">⁠Passenger Charter</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-3 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Aerial Photography</a></h4>
                              </div>
                          </div>
                      </div>
                  </div>

                  <br />

                  <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-4 text-center mb-30" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Flower Dropping</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-5 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Leisure and Sports</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-6 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Weddings and Pre-Shoots</a></h4>
                              </div>
                          </div>
                      </div>
                  </div>

                  <br />

                  <div class="row">
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-7 text-center mb-30" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">Medical Evacuation</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-8 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">⁠Branding and Advertising</a></h4>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-cat offer-9 text-center mb-50" onClick={() => handleClick('Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem tenetur aspernatur explicabo quod odit optio.')}>
                              <div class="cat-cap py-5">
                                  <h4><a href="services.html">⁠Special Events - Production Launches</a></h4>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

    {/* Our Offers */}

    {/* <div class="container-xxl bg-light my-3 py-0">
              <div class="container py-5">
                  <div class="text-center mx-auto mb-5 wow animated zoomIn" data-wow-delay="0.6s" style={{ maxWidth: '500px' }}>
                      <div class="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Offers</div>
                      <h1 class="display-6 mb-0">Exclussive Welcome Offers</h1>
                  </div>
                  <div class="row g-4 justify-content-center">
                      <div class="col-lg-4 col-md-6 wow animated slideInLeft" data-wow-delay="0.3s">
                          <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                              <div class="text-center p-4 pt-0">
                                  <div class="d-inline-block bg-dark text-secondary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                      <small>Offer</small>
                                  </div>
                                  <h4 class="mb-3">Solid for reduced rates</h4>
                                  <div class="causes-progress bg-light p-3 pt-2">
                                      <img class="img-fluid" src="img/home_2.webp" alt="" />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                              <div class="text-center p-4 pt-0">
                                  <div class="d-inline-block bg-dark text-secondary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                      <small>Offer</small>
                                  </div>
                                  <h4 class="mb-3">Usually offer when an Aircraft</h4>
                                  <p><li> Is landing to pickup passengers</li>
                                      <li>Has to flown empty back to its home</li>
                                  </p>
                                  <div class="causes-progress bg-light p-3 pt-2">
                                      <img class="img-fluid" src="img/C01.webp" alt="" />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 col-md-6 wow animated slideInRight" data-wow-delay="0.3s">
                          <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                              <div class="text-center p-4 pt-0">
                                  <div class="d-inline-block bg-dark text-secondary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                      <small>Offer</small>
                                  </div>
                                  <h5 class="mb-3">Solely for the benefit of our clients</h5>
                                  <div class="causes-progress bg-light p-3 pt-2">
                                      <img class="img-fluid" src="img/home_1.webp" alt="" />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}


          {/* News Area */}

          {/* <div class="container-fluid py-0 wow animated slideInUp" data-wow-delay="0.1s">
              <div class="container py-4">
                  <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                      <h5 class="fw-bold text-primary text-uppercase">Latest News</h5>
                      <h1 class="mb-3">Keep up with Our Most Recent Airlines News</h1>
                      <Link onClick={scrollToTop} class="btn btn-primary py-2 px-3 me-0" to="/Destination">
                          View All News
                          <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                              <i class="fa fa-arrow-right"></i>
                          </div>
                      </Link>
                  </div>
                  <div class="row">
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.3s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Helicopter (3).jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-dark text-secondary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala3.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-dark text-secondary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.9s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala4.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-dark text-secondary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}

          {/* <div class="favourite-place place-padding py-5 wow animated slideInUp" data-wow-delay="0.6s">
              <div class="container">

                  <div class="row">
                      <div class="col-lg-12">
                          <div class="section_title_div text-center position-relative pb-3 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                              <h5 class="fw-bold text-primary text-uppercase">FEATURED TOURS DESTINATIONS</h5>
                              <h1 class="mb-3">It's a Big World Out There, Go Explore</h1>
                              <Link onClick={scrollToTop} class="btn btn-primary py-2 px-3 me-3" to="/Destination">
                                  View All Destinations
                                  <div class="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                      <i class="fa fa-arrow-right"></i>
                                  </div>
                              </Link>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s"> 
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Yala.jpg" alt="tour1" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to  Nuwara Eliya</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Yala5.jpg" alt="tour2" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to Kandy</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Yala4.jpg" alt="tour3" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to Yala</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Helicopter (1).jpg" alt="tour4" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to Galle</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Helicopter (3).jpg" alt="tour5" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to Trinco</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-4 col-lg-4 col-md-6 py-3 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="single-place mb-30">
                              <div class="place-img">
                                  <img src="images/Lake view (1).jpg" alt="tour6" />
                              </div>
                              <div class="place-cap">
                                  <div class="place-cap-top">
                                      <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                      <h3><a href="#">Ratmalana to Downsouth</a></h3>
                                  </div>
                                  <div class="place-cap-bottom">
                                      <ul>
                                          <li><i class="far fa-clock"></i>5 Hours</li>
                                          <li><i class="fas fa-map-marker-alt"></i>Ratmalana</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}

          {/* Our Membership */}

          <section id="values_section">
              <div class="container-fluid py-0 wow fadeInUp" data-wow-delay="0.1s">
                  <div class="container">
                      <div class="section_title_div text-center position-relative pb-0 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                          <h1 class="mb-1">MEMBERSHIP</h1>
                      </div>

                      <div class="row g-3">
                          <div class="col-lg-4">
                              <div class="row g-5">
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.2s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>24/7 Dedicated Personal Advisor</h4>
                                  </div>
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>Add Rates Per Every Travel</h4>
                                  </div>
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.6s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>Free Travel For The Added Rates</h4>
                                  </div>
                              </div>
                          </div>
                          <div class="col-lg-4  wow zoomIn" data-wow-delay="0.9s" style={{ minHeight: '350px' }}>
                              <div class="position-relative h-100">
                                  <img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="images/Waterfall (1).jpg" style={{ objectFit: 'cover' }} />
                              </div>
                          </div>
                          <div class="col-lg-4">
                              <div class="row g-5">
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.4s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>Lifestyle Management</h4>
                                  </div>
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>Handling Ticket Reservations For Events</h4>
                                  </div>
                                  <div class="col-12 wow zoomIn" data-wow-delay="0.8s">
                                      <div class="bg-dark text-secondary rounded d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                                          <i class="fa fa-check-square text-white"></i>
                                      </div>
                                      <h4>Get Exclusive Offers For Travels</h4>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </section>

          <br /><br />

          <section id="get-started" class="get-started section-bg">
              <div class="container">

                  <div class="row justify-content-between gy-4">

                      <div class="col-lg-2" data-aos="fade"></div>

                      <div class="col-lg-8" data-aos="fade-up">
                          <form action="#" method="post" class="php-email-form">
                              <div class="section_title_div text-center position-relative pb-0 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                                  <h2 class="mb-1">Become a Member</h2>
                              </div>

                              <div class="row gy-3">

                                  <div class="col-md-12">
                                      <input type="text" name="name" class="form-control" placeholder="Name" required />
                                  </div>

                                  <div class="col-md-12 ">
                                      <input type="email" class="form-control" name="email" placeholder="Email" required />
                                  </div>

                                  <div class="col-md-12">
                                      <input type="text" class="form-control" name="phone" placeholder="Phone" required />
                                  </div>

                                  <div class="col-md-12">
                                      <textarea class="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                                  </div>

                                  <div class="col-md-12 text-center">
                                      <div class="loading">Loading</div>
                                      <div class="error-message"></div>
                                      <div class="sent-message">Your quote request has been sent successfully. Thank you!</div>

                                      <Link className={`btn btn-primary py-2 px-3 ${isDisabled ? 'disabled' : ''}`} to={isDisabled ? '#' : '/desired-path'} onClick={(e) => isDisabled && e.preventDefault()}>
                                          Click Now
                                          <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                              <i className="fa fa-arrow-right"></i>
                                          </div>
                                      </Link>

                                  </div>

                              </div>
                          </form>
                      </div>

                      <div class="col-lg-2" data-aos="fade"></div>

                  </div>

                  <br />

              </div>
          </section>

          {/* Our Membership */}

          {/* <div class="container-xxl py-0 category">
              <div class="container">

                  <div class="section_title_div text-center position-relative pb-0 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                      <h1 class="mb-3">MEMBERSHIP</h1>
                  </div>

                  <div class="row g-3">

                      <div class="col-lg-7 col-md-6">
                          <div class="row g-3">
                              <div class="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-5.jpg" alt="aura_offer1" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">24/7 Dedicated Personal Advisor</h5>
                                      </div>
                                  </a>
                              </div>
                              <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-3.jpg" alt="aura_offer2" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">Add Rates Per Every Travel</h5>
                                      </div>
                                  </a>
                              </div>
                              <div class="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-6.jpg" alt="aura_offer3" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">Lifestyle Management</h5>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{ minHeight: '350px' }}>
                          <a class="position-relative d-block h-100 overflow-hidden" href="/">
                              <img class="img-fluid position-absolute w-100 h-100" src="img/category/cat-9.jpg" alt="aura_offer4" style={{ objectFit: 'cover' }} />
                              <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                  <h5 class="m-0">Handling Ticket Reservations For Events</h5>
                              </div>
                          </a>
                      </div>
                  </div>

                  <div class="row g-3">
                      <div class="col-lg-12 col-md-6">
                          <div class="row g-3">

                              <div class="col-lg-4 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-10.jpg" alt="aura_offer5" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">Free Travel For The Added Rates</h5>
                                      </div>
                                  </a>
                              </div>
                              <div class="col-lg-4 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-7.jpg" alt="aura_offer6" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">Handling Hotel Arrangements</h5>
                                      </div>
                                  </a>
                              </div>
                              <div class="col-lg-4 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                  <a class="position-relative d-block overflow-hidden" href="/">
                                      <img class="img-fluid" src="img/category/cat-7.jpg" alt="aura_offer7" />
                                      <div class="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                          <h5 class="m-0">Get Exclusive Offers For Travels</h5>
                                      </div>
                                  </a>
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
          </div> */}

          {/* <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
              <div class="container quote px-lg-0">
                  <div class="row g-0 mx-lg-0">
                      <div class="col-lg-6 ps-lg-0" style={{ minHeight: '400px' }}>
                          <div class="position-relative h-100">
                              <img class="position-absolute img-fluid w-100 h-100 wow animated zoomIn slideInLeft" data-wow-delay="0.6s" src="img/about2.jpg" style={{ objectFit: 'cover' }} alt="mambership" />
                          </div>
                      </div>
                      <div class="col-lg-6 quote-text py-1 wow fadeIn" data-wow-delay="0.5s">
                          <div class="p-lg-5 pe-lg-0">

                              <h1 class="display-5 mb-2">We're With You Always</h1>
                              <p class="mb-4 pb-2">Membership has it's Privileges & Last for an Eternity.</p>
                              <form>
                                  <div class="row g-3">
                                      <div class="col-12 col-sm-6">
                                          <select class="form-select border-0" style={{ height: '55px' }}>
                                              <option selected>Select A Title</option>
                                              <option value="Mr">Mr</option>
                                              <option value="Mrs">Mrs</option>
                                              <option value="Miss">Miss</option>
                                          </select>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <input type="text" class="form-control border-0" placeholder="Full Name" style={{ height: '55px' }} />
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <input type="email" class="form-control border-0" placeholder="Contact Number" style={{ height: '55px' }} />
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <input type="email" class="form-control border-0" placeholder="Email Address" style={{ height: '55px' }} />
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <select class="form-select border-0" style={{ height: '55px' }}>
                                              <option selected>How Do You Wish To Be Reached</option>
                                              <option value="Call us">Call us</option>
                                              <option value="Email us">Email us</option>
                                          </select>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <input type="email" class="form-control border-0" placeholder="Hometown" style={{ height: '55px' }} />
                                      </div>
                                      <div class="col-12">
                                          <textarea class="form-control border-0" placeholder="How often do you travel?"></textarea>
                                      </div>
                                      <div class="col-12">
                                          <textarea class="form-control border-0" placeholder="What benefits of Membership are you most interested in?"></textarea>
                                      </div>
                                      <div class="col-12">
                                          <button class="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}

    {/* Our Services */}

    {/* Why Aura Airlines Charter */}

          {/* <div class="container-xxl py-5">
              <div class="container">
                  <div class="row g-5">
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="border-start border-5 border-primary ps-4 mb-5">
                              <h5 class="fw-bold text-primary text-uppercase">Why Choose Us!</h5>
                              <h1 class="display-6 mb-0">
                                Why Aura Airlines Charter
                              </h1>
                          </div>
                         
                          <div class="row gy-5 gx-4">
                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Island wide coverage</h6>
                                  </div>
                              
                              </div>
                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Full of comfort, Luxury and convenience</h6>
                                  </div>
                              
                              </div>
                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Anytime, Anywhere</h6>
                                  </div>
                                  
                              </div>
                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Ensure safety, Security and privacy</h6>
                                  </div>
             
                              </div>
                              
                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Available 24/7/365</h6>
                                  </div>
             
                              </div>

                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Guaranteed safety and our dedication</h6>
                                  </div>
             
                              </div>

                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Best value for money</h6>
                                  </div>
             
                              </div>

                              <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                                  <div class="d-flex align-items-center mb-0">
                                      <i
                                          class="fa fa-check fa-2x text-primary flex-shrink-0 me-3"
                                      ></i>
                                      <h6 class="mb-0">Preeminent and personalized service</h6>
                                  </div>
             
                              </div>

                          </div>
                      </div>
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ maxHeight: '400px' }}>
                              <img
                                  class="position-absolute w-100 h-100"
                                  src="img/Choose.jpg"
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                              />
                              <div class="position-absolute top-0 start-0 bg-white pe-3 pb-3" style={{ width: '200px', height: '200px' }}>
                                  <div class="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3" >
                                      <h1 class="text-white">6</h1>
                                      <h2 class="text-white">Years</h2>
                                      <h5 class="text-white mb-0">Experience</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="row g-5 py-3">

                  <div class="col-lg-6 wow animated zoomIn slideInLeft" data-wow-delay="0.6s">
                      <div class="blog-item">
                          <div class="position-relative overflow-hidden rounded-top-one">
                              <img class="img-fluid" src="img/about5.jpg" alt="" />
                          </div>
                          <div class="bg-dark-one d-flex align-items-center rounded-bottom-one p-2">
                              <h4 class="h5 text-uppercase text-light">Safety</h4>
                          </div>
                          <div class="bg-dark-one d-flex align-items-center rounded-bottom-one p-2">
                              <h3 class="h6 text-light">Aura Airlines considers safety as its prime consideration and therefore has implemented safety management systems (SMS) throughout our operation both horizontally and vertically.Highly experienced crew will provide extra support with safety ensuring health and personal welfare of our valuable clients.</h3>
                          </div>
                      </div>
                  </div>

                  <div class="col-lg-6 wow animated zoomIn slideInRight" data-wow-delay="0.6s">
                      <div class="blog-item">
                          <div class="position-relative overflow-hidden rounded-top-one">
                              <img class="img-fluid" src="img/about5 - Copy.jpg" alt="" />
                          </div>
                          <div class="bg-dark-one d-flex align-items-center rounded-bottom-one p-2">
                              <h4 class="h5 text-uppercase text-light">Dedication</h4>
                          </div>
                          <div class="bg-dark-one d-flex align-items-center rounded-bottom-one p-2">
                              <h3 class="h6 text-light">Our product reflects and improves the quality of life with the mission of the airline to make air travel an integral part of the tourism industry and Aura Airlines to be the most flexible, reliable, and safest mode of transport for domestic air travel among tourists, corporate clients, and locals.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></h3>
                          </div>
                      </div>
                  </div>

              </div>

          </div> */}

          

      </>

      
  )
}

export default Main