import React, { useState } from 'react';
import Axios from 'axios';
import "./reservation.css";
import Swal from 'sweetalert2';

const Reservation = () => {

    const url = "https://auraairline.com/aura_airlines/CustomerInquiryDetails"

    const [data, setData] = useState({
        title: '',
        first_name: '',
        last_name: '',
        contact_number: '',
        email_address: '',
        flight_info: '',
        departure_city: '',
        destination_city: '',
        depature_date: '',
        passanger_count: '',
        special_req: ''
    });

    function submit(e){
        
        e.preventDefault();

        Axios.post(url,{
            title:data.title,
            first_name:data.first_name,
            last_name:data.last_name,
            contact_number:data.contact_number,
            email_address:data.email_address,
            flight_info:data.flight_info,
            departure_city:data.departure_city,
            destination_city:data.destination_city,
            depature_date:data.depature_date,
            passanger_count:data.passanger_count,
            special_req:data.special_req
        })
            .then(res=>{
                const responseData = res.data;
                console.log(responseData);

                if (responseData.status === 'SUCCESS') {
                    Swal.fire({
                        title: 'Success',
                        text: responseData.message,
                    }).then(() => {
                        // Reset the form
                        setData({
                            title:"",
                            first_name:"",
                            last_name:"",
                            contact_number:"",
                            email_address:"",
                            flight_info:"",
                            departure_city:"",
                            destination_city:"",
                            depature_date:"",
                            passanger_count:"",
                            special_req:""
                        });
                    });
                    
                } else {
                    // Handle other response statuses or errors
                    Swal.fire({
                        title: 'Error',
                        text: 'Reservation request failed. Please try again.',
                    });
                }

            })
            .catch(error => {
                console.error("An error occurred:", error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred while processing your request. Please try again later.',
                });
            });
                
        setData({
            title:"",
            first_name:"",
            last_name:"",
            contact_number:"",
            email_address:"",
            flight_info:"",
            departure_city:"",
            destination_city:"",
            depature_date:"",
            passanger_count:"",
            special_req:""
          });

    }

    function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        /*console.log(newdata)*/

    }

    return (
        <>
            <div class="container-fluid page-header_booking">
                <h1 class="display-3 text-uppercase text-white mb-3">Ticket Reservation</h1>
                <div class="d-inline-flex text-white">
                    <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                    <h6 class="text-body m-0 px-3">/</h6>
                    <h6 class="text-uppercase text-body m-0">Reservation</h6>
                </div>
            </div>

            <div class="container-fluid my-3 px-0">
                <div class="video wow fadeInUp" data-wow-delay="0.1s">
                    <button type="button" class="btn-play" data-bs-toggle="modal" data-src="https://drive.google.com/file/d/1-nRbPRxrB6DWlxiny6tK8ndFCr3xnbyl/preview" data-bs-target="#videoModal">
                        <span></span>
                    </button>

                    <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content rounded-0">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Aura Airlines Video</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    <div class="ratio ratio-16x9">
                                        <iframe class="embed-responsive-item" src="https://drive.google.com/file/d/1-nRbPRxrB6DWlxiny6tK8ndFCr3xnbyl/preview" id="video" allowfullscreen allowscriptaccess="always" title='Ticket Payment Process' allow="autoplay"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <h1 class="text-white mb-4">Ready to Travel? We’ve Got Great Flight Deals!</h1>
                    <h3 class="text-white mb-4"><i class="fa fa-phone text-primary me-3"></i>Call us 24/7 at <span style={{ color: '#FF6F0F' }}>+(94) 77 3333966</span> to Get Aura Flights!</h3>
                    {/* <div class="col-lg-6">
                        <div class="h-100 para_one">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quo, modi voluptatem eum atque possimus tempora, iure ad quae rerum deserunt dicta tempore cupiditate. Totam sed unde, tempore quae, perspiciatis facere, cupiditate alias nostrum numquam veniam modi voluptates nam. Qui, debitis.</p>
                        </div>
                    </div> */}
                </div>
                <div class="container position-relative wow animated zoomIn" data-wow-delay="0.3s" style={{ marginTop: '-6rem' }}>
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="bg-light text-center p-5">
                                <h1 class="mb-4">Book A Trip</h1>
                                <p style={{ textAlign: 'center' }}>Customers can make a reservation after completing this form.Customer service will verify for availability and confirm reservations made by consumers.</p>
                                <form id="bookForm" onSubmit={(e)=> submit(e)}>
                                    <div class="row g-3">
                                 
                                        <div className="col-12 col-sm-4">
                                            <select onChange={(e)=>handle(e)} id="title" value={data.title} name="title" className="form-select border-0" style={{height: '50px'}} required>
                                                <option value="" hidden="true">Select Type</option>
                                                <option value="Mr">Mr</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Miss">Miss</option>
                                            </select>
                                        </div>

                                        <div class="col-12 col-sm-4">
                                            <input type="text" class="form-control border-0" onChange={(e)=>handle(e)} id="first_name" value={data.first_name} placeholder="First Name" style={{ height: '55px' }} required />
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <input type="text" class="form-control border-0" onChange={(e)=>handle(e)} id="last_name" value={data.last_name} placeholder="Last Name" style={{ height: '55px' }} required />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input type="text" class="form-control border-0" onChange={(e)=>handle(e)} id="contact_number" value={data.contact_number} placeholder="Contact Number" style={{ height: '55px' }} required />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input type="text" class="form-control border-0" onChange={(e)=>handle(e)} id="email_address" value={data.email_address} placeholder="Email Address" style={{ height: '55px' }} required />
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <select onChange={(e)=>handle(e)} id="flight_info" value={data.flight_info} name="flight_info" className="form-select border-0" style={{height: '50px'}} required>
                                                <option value="" hidden="true">Flight Information</option>
                                                <option value="OneWay">One Way</option>
                                                <option value="RoundTrip">Round Trip</option>
                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <select onChange={(e)=>handle(e)} id="departure_city" value={data.departure_city} name="departure_city"className="form-select border-0" style={{height: '50px'}} required>
                                                <option value="" hidden="true">Departure City</option>
                                                <option value="Galle">Galle</option>
                                                <option value="Tangalle">Tangalle</option>
                                                <option value="Koggala">Koggala</option>
                                                <option value="Yala">Yala</option>
                                                <option value="Wilpattu">Wilpattu</option>
                                                <option value="Udawalawe">Udawalawe</option>
                                                <option value="Trincomalee">Trincomalee</option>
                                                <option value="Batticaloa">Batticaloa</option>
                                                <option value="Arugam Bay">Arugam Bay</option>
                                                <option value="Jaffna">Jaffna</option>
                                                <option value="Vavuniya">Vavuniya</option>
                                                <option value="NuwraEliya">NuwraEliya</option>
                                                <option value="Kandy">Kandy</option>
                                                <option value="Norwood">Norwood</option>
                                                <option value="Sigiriya">Sigiriya</option>
                                                <option value="Anuradhapura">Anuradhapura</option>
                                                <option value="Kataragama">Kataragama</option>
                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-4">
                                            <select onChange={(e)=>handle(e)} id="destination_city" value={data.destination_city} name="destination_city" className="form-select border-0" style={{height: '50px'}} required>
                                                <option value="" hidden="true">Destination City</option>
                                                <option value="Galle">Galle</option>
                                                <option value="Tangalle">Tangalle</option>
                                                <option value="Koggala">Koggala</option>
                                                <option value="Yala">Yala</option>
                                                <option value="Wilpattu">Wilpattu</option>
                                                <option value="Udawalawe">Udawalawe</option>
                                                <option value="Trincomalee">Trincomalee</option>
                                                <option value="Batticaloa">Batticaloa</option>
                                                <option value="Arugam Bay">Arugam Bay</option>
                                                <option value="Jaffna">Jaffna</option>
                                                <option value="Vavuniya">Vavuniya</option>
                                                <option value="NuwraEliya">NuwraEliya</option>
                                                <option value="Kandy">Kandy</option>
                                                <option value="Norwood">Norwood</option>
                                                <option value="Sigiriya">Sigiriya</option>
                                                <option value="Anuradhapura">Anuradhapura</option>
                                                <option value="Kataragama">Kataragama</option>
                                                <option value="Koggala">Koggala</option>
                                                <option value="Mattala">Mattala</option>
                                                <option value="Mattala/Weerawila">Mattala/Weerawila</option>
                                                <option value="Anuradhapura/Palavi">Anuradhapura/Palavi</option>
                                                <option value="Mattala/Ratnapura">Mattala/Ratnapura</option>
                                                <option value="Minneriya">Minneriya</option>
                                                <option value="Ampara/Minneriya">Ampara/Minneriya</option>
                                                <option value="Ampara">Ampara</option>
                                                <option value="Palali">Palali</option>
                                                <option value="Diyatalawa">Diyatalawa</option>
                                                <option value="Kegalle/Digana">Kegalle/Digana</option>
                                                <option value="Ginigatthena">Ginigatthena</option>
                                                <option value="Minneriya/Dambulla">Minneriya/Dambulla</option>
                                                <option value="Vavuniya/Sigiriya">Vavuniya/Sigiriya</option>
                                                <option value="Mattala/Weerawila">Mattala/Weerawila</option>
                                            </select>
                                        </div>

                                        <div class="col-12 col-sm-6">
                                            <input type="date" class="form-control border-0" onChange={(e)=>handle(e)} id="depature_date" value={data.depature_date} placeholder="Date of Departure" style={{ height: '55px' }} required />
                                        </div>
                                       
                                        <div className="col-12 col-sm-6">
                                            <select onChange={(e)=>handle(e)} id="passanger_count" value={data.passanger_count} name="passanger_count" className="form-select border-0" style={{height: '50px'}} required>
                                                <option value="" hidden="true">Select Passanger Count</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                            </select>
                                        </div>
                                        
                                        <div class="col-12">
                                            <textarea class="form-control border-0" onChange={(e)=>handle(e)} id="special_req" value={data.special_req} placeholder="Special Request"></textarea>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container border-around">
                <div class="row mx-auto d-flex justify-content-center">
                    <div class="col-md-4 wow animated slideInLeft" data-wow-delay="0.3s"><img class="d-block w-100" src="img/06-rounded.webp" alt="" /></div>
                    <div class="col-md-4 wow animated slideInLeft" data-wow-delay="0.3s"><img class="d-block w-100" src="img/00004-rounded.webp" alt="" /></div>
                    <div class="col-md-4 wow animated slideInLeft" data-wow-delay="0.3s"><img class="d-block w-100" src="img/wpimag-rounded.webp" alt="" /></div>
                </div>
            </div>

        </>
    )
}

export default Reservation