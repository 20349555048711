import React from 'react'
import QRCode from 'qrcode.react';
import './profile.css';

const Profile_one = () => {

    const profileUrl = 'https://auralanka.com/';

  return (
    <>

          <div class="container-fluid page-header_profile"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Profile</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Profile</h6>
              </div>
          </div>

          <div class="container-xxl py-0">
              <div class="container">
                  <div class="row g-5">
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                              <img
                                  class="position-absolute w-100 h-100"
                                  src="img/team/sample.webp"
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                       
                              />
                          </div>
                      </div>
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="h-100">
                              <div class="border-start border-5 border-primary ps-4 mb-5">
                                  <h6 class="text-body text-uppercase mb-2">Chairman / Director</h6>
                                  <h1 class="display-6 mb-0">
                                        Dr. Viranjith Thambugala
                                  </h1>
                              </div>
                              <p>
                                Dr. Viranjith Thambugala is a highly esteemed and influential figure in the fields of indigenous medicine and entrepreneurship, renowned for his significant global impact. His illustrious career is characterized by a wealth of expertise and profound influence, which has earned him widespread recognition across various nations, notably in the United States, United Arab Emirates, and Japan.
                              </p>
                              <p>
                                As the Chairman and proprietor of the Aura Lanka group of companies, Dr. Viranjith Thambugala presides over a diverse and dynamic portfolio. This portfolio includes Aura Lanka Herbals, Aura Lanka Gem and Museum, Aura Lanka Entertainment, Aura Lanka Event Management, Aura Lanka Security Solutions, and Aura Airlines. His leadership within these enterprises reflects an unwavering commitment to excellence and an unwavering drive for innovation.
                              </p>
                              <p class="mb-4">
                                Dr. Viranjith Thambugala's extensive experience in international trade, with a particular emphasis on exporting, stands as a testament to his relentless pursuit of quality and success. His visionary approach has propelled the expansion of his business empire into multiple sectors and countries, leaving an enduring imprint on the global business landscape.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
    </>
  )
}

export default Profile_one