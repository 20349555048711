import React from 'react'
import QRCode from 'qrcode.react';
import './profile.css';

const Profile_four = () => {

    const profileUrl = 'https://auralanka.com/';

  return (
    <>

          <div class="container-fluid page-header_profile"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Profile</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Profile</h6>
              </div>
          </div>

          <div class="container-xxl py-0">
              <div class="container">
                  <div class="row g-5">
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                              <img
                                  class="position-absolute w-100 h-100"
                                  src="img/team/sample.webp"
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                       
                              />
                          </div>
                      </div>
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="h-100">
                              <div class="border-start border-5 border-primary ps-4 mb-5">
                                  <h6 class="text-body text-uppercase mb-2">Head of Administration, Sales & Finance</h6>
                                  <h1 class="display-6 mb-0">
                                        Ruwan Nayanajith
                                  </h1>
                              </div>
                              <p>
                                Coming from an aircraft engineering background, Mr. Ruwan Nayanajith has excelled in the management of company systems, introducing a distinctive fusion of technical and managerial expertise. His qualifications in aircraft engineering underscore his profound understanding of the aviation sector. Additionally, he has attained a master's degree in aviation management, further cementing his knowledge and proficiencies in this field.
                              </p>
                              <p>
                                In his current capacity, Mr. Ruwan Nayanajith holds the responsibility for supervising the holistic functionality of the business. His transition from engineering to business management exemplifies his adaptability and aptitude for excelling in various domains, effectively merging technical acumen with a strategic mindset. Mr. Ruwan Nayanajith continues to hold a pivotal role in steering the company towards success, leveraging his unique background and qualifications to contribute to the organization's growth and prosperity.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
    </>
  )
}

export default Profile_four