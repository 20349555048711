import React from 'react'
import "./conditions.css";

const Conditions = () => {

  return (
    <>
    
    <div class="container-fluid page-header_booking">
        <h1 class="display-3 text-uppercase text-white mb-3">TERMS & CONDITIONS</h1>
        <div class="d-inline-flex text-white">
            <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
            <h6 class="text-body m-0 px-3">/</h6>
            <h6 class="text-uppercase text-body m-0">TERMS & CONDITIONS</h6>
        </div>
    </div>

    <div class="condition">
        <div class="row gx-5">
            
            <div class="col-lg-12">
               
                <div class="rounded glassmorphism-conditions p-5">
                    <ul class="nav nav-pills justify-content-between mb-3 glassmorphism-pills">
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100 active" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-1">TERMS OF USE</a>
                        </li>
                        <li class="nav-item navitem w-25">
                                <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-2">APPLICABILITY</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-6">BAGGAGE</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-3">FARES, TAXES, FEES, AND CHARGES/SURCHARGES</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-4">CHECK-IN, BOARDING AND CONDUCT</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-5">REFUSAL AND LIMITATION OF CARRIAGE</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-7">SCHEDULES DELAYS AND CANCELLATIONS</a>
                        </li>
                        <li class="nav-item navitem w-25">
                            <a class="nav-link navlink text-uppercase text-center w-100" style={{ fontSize: '18px'}} data-bs-toggle="pill" href="#pills-8">GENERAL INFORMATIONS & GUIDE</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-1">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>DEFINITIONS:</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>In these Conditions of Carriage, the expressions set forth below shall have the following meanings:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	“Aura Airlines”, “We”, “we”, “Our” or “us” means Aura Airlines (Private) Limited.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	“Baggage” or “baggage” means the Passenger’s personal property accompanying the Passenger on the journey either taken on board the aircraft or accepted by Aura Airlines at the same time of boarding such passenger on the aircraft in such circumstances as set out hereinafter for transportation and delivery to such passenger at the agreed Destination set out in the Passenger Ticket by any other method of transportation including surface transportation. Unless otherwise specified, Baggage referred herein includes both the Passenger’s Checked and Unchecked Baggage.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	“Carriage” means the transportation of Passengers and Baggage provided under the contract of carriage as evidenced by the Ticket issued to the Passenger.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	“Damage” means and includes death, wounding, or bodily injury to a Passenger, loss, partial loss, theft destruction or other damage to Baggage, arising during transportation by air or in the course of embarkation or disembarkation in relation to Carriage performed by Aura Airlines.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>5.	“Destination” means the place of destination set forth in the Ticket.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>6.	“Force Majeure” means and includes any laws, regulations, requirements, acts, demands, orders, interposition’s or interference of any governmental authority, acts of God, sanctions (financial or otherwise), seizure by government under legal process, hijacking, riots, civil commotion’s, strikes or labour stoppage (whether resulting from disputes between a party hereto and its employees or between other parties whose involvement is essential for the operation of the Aircraft), for, fog, flood, weather, inability to obtain fuel, quarantines, requisition of the Aircraft, Governmental military airlift requirements, war, rebellion, insurrections, hostilities or hazards or dangers incident thereto, damage or accident to the Aircraft or failures, failure or refusal by any governmental authority to issue approvals, clearances, permits, operating, layover or traffic rights or rescission or revocation thereof provided that timely applications for such approvals, clearances, permits or rights, aircraft on ground (AOG) situation due to technical defects in the aircraft or any other cause whatsoever which is reasonably beyond the control of Aura Airlines.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>7.	“Passenger” means any person (except members of crew) travelling on Aura Airlines who may in these Conditions of Carriage be sometimes referred to as “You” “you” or “Your”.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>8.	“Tariff” means fares and charges published by Aura Airlines electronically or on paper and conditions applicable thereto.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>9.	“Ticket” means the document (including any electronic document) issued by or on behalf of us setting out the agreed specifications of the Carriage and shall include in particular a proposal in relation to which an invoice has been issued to passengers.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>10.	“Website” means the internet site www.auraairline.com provided by Aura Airlines for the purpose of inter alia Passengers making online bookings and to access information about Aura Airlines.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-2">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>APPLICABILITY</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	General: These Conditions of Carriage apply to the carriage by air of Passengers and Baggage, including services incidental thereto performed by Aura Airlines and in relation to any liability that Aura Airlines may have in relation to that Carriage.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	Charters:  If carriage is performed pursuant to a charter agreement, these Conditions of Carriage apply to the extent they are incorporated in such charter agreement.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	Terms & Conditions Prevail: Except as provided in these Conditions of Carriage, in the event of inconsistency between these Conditions of Carriage and any other regulations we may have dealing with particular subjects, these Conditions of Carriage shall prevail.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	Overriding Law: In the event any provision of these Conditions of Carriage are adjudged or deemed to be illegal, invalid, void and or unenforceable, the remaining provisions of these Conditions of Carriage shall not in any way be affected or impaired but shall remain binding to such extent as permitted by applicable law.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-3">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>FARES, TAXES, FEES, AND CHARGES/SURCHARGES</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	Fares apply only for Carriage from the airport/dock at the point of origin to the airport/dock at the point of Destination, unless otherwise expressly stated. Fares do not include ground transport service between airports/ terminals/docks and hotels. Passenger’s fare will be calculated in accordance with Aura Airline’s Tariff in effect on the respective date of payment for travel. Any changes to the ticket (including changes to travel dates, itinerary or any other conditions relating to the Carriage) requested by the passenger may require payment of a change fee and/or additional charges.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	Fares must be paid in full when a booking is made. In the event that the fare has not been paid in full when a booking is confirmed for any reason whatsoever, Aura Airlines reserves the right to cancel the booking prior to check-in and/or to disallow the Passenger to board the aircraft.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	We endeavor to maintain the accuracy of all fares, flight schedules and routes published at the time of publication. Aura Airlines reserves the right to revise any fares and flight schedules at any time and from time to time without prior notice.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	The Passenger hereby acknowledges and agrees that personal data has been provided to Aura Airlines for the purposes of making bookings for Carriage and to provide the Passenger with confirmation of that booking, providing and developing ancillary services and facilities, facilitating immigration and entry procedures, accounting, billing and auditing, checking credit or other payment cards, security, administrative and legal purposes, credit card issuance, systems testing, maintenance and development, statistical analysis and to facilitate any future dealings with Passenger. For these purposes, by entering into a contract of Carriage with Aura Airlines the Passenger authorizes Aura Airlines to retain and use personal data and to transmit same to its own offices, authorized agents and third-party business associates, government agencies, other carriers or the providers of the services mentioned above.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>5.	Applicable taxes, fees and charges imposed by the government or by municipal or other authority, or by the operator of an airport, shall be payable by the Passenger. At the time the Passenger purchases the Ticket, the Passenger will be advised of taxes, fees and charges not included in the fare, most of which will normally be shown separately on the Ticket.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-4">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>CHECK-IN, BOARDING AND CONDUCT</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	Check-in will be 30 minutes prior to the scheduled departure time. The Passenger is responsible for ensuring that all travel documents including a valid ticket and passport and/or National Identity Card required to identify the Passenger are available at the time of check in.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	Aura Airlines reserves the right to refuse carriage or to refuse to check in a Passenger, without any liability to such Passenger and without having to refund any fare paid, in the following circumstances:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>a.	If the Passenger reports or attempts to check in at any time where less than thirty (30) minutes remain to the scheduled departure time of the flight.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>b.	If the Passenger has not fully paid any fare or other fees or charges due to Aura Airlines.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>c.	If the Passenger has caused disturbance or made himself objectionable to Aura Airlines staff or other passengers, whether physically or verbally.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>d.	If in the judgment of Aura Airlines, the Passenger is not fit to travel due to drunkenness/intoxication/influence of drugs or any reasonably apparent medical condition or poses or could pose a danger or threat to the health and safety of himself, other Passengers or property or the aircraft or its operations.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>e.	If the passenger has failed to submit to any required medical/security checks.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>f.	If the Ticket has been acquired unlawfully or has been purchased from an entity other than Aura Airlines or its authorized agent, or has been reported as being lost or stolen, or is a counterfeit ticket, or any flight or electronic coupon has been altered by anyone other than Aura Airlines or its authorized agent or the person presenting the Ticket cannot prove that he is the person named in the Ticket; or</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>g.	If such action is necessary in order to comply with any applicable laws, regulations or orders of any state or country to be flown from, into or over.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	There are no seat allocations according to specific seat numbers. Aura Airlines operates a single-class and free-seating policy. Priority for boarding is given to the handicapped, elderly and those travelling with children.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	The Passenger shall not enter or stay in the aircraft in any event wherein the opinion of any servant or agent of Aura Airlines, his/her mental status or physical condition is objectionable to other passengers, or it involves him/her being a hazard to himself/herself, other persons or property and Aura Airlines shall not be liable or responsible to provide substituted Carriage for such a person.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>5.	The Passenger shall remain seated at all times during the flight.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>6.	The Passenger shall not smoke in or in the vicinity of the aircraft, throw anything from the aircraft or walk in, about or in front of the aircraft or in the vicinity of the engine and propeller at any point.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>7.	The Passenger shall not, whilst on board the aircraft, consume any intoxicating beverage or illegal drugs.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>8.	If the Passenger fails to check in on time or fails to board the aircraft by the time the aircraft departs, the fare paid by the Passenger will not be refunded for any reason whatsoever.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>9.	The Passenger shall submit to any security, customs or health checks by Government of Sri Lanka or airport officials or as required by the Aura Airlines.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>10.	If the Passenger conducts himself aboard the aircraft so as to endanger the aircraft or any person or property on board, or obstructs the crew in the performance of their duties, or fails to comply with any instruction of the crew, or behaves in a manner to which other passengers may reasonably object, we may take such measures as it deems necessary to prevent continuation of such conduct, including restraint of the Passenger.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>11.	The Passenger may not operate aboard the aircraft portable radios, electronic games or transmitting devices including radio-controlled toys and walkie-talkies. The Passenger shall not operate any other electronic devices on board without our express permission.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-5">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>REFUSAL AND LIMITATION OF CARRIAGE</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	Passengers acknowledge that those with special needs (except for accompanied minors) cannot be accommodated under usual circumstances due to the inability to provide adequate facilities at all locations. Passengers with special needs are required to contact Aura Airlines for confirmation of acceptance prior to purchasing tickets.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	Passengers acknowledge that the aircraft operated by Aura Airlines is unpressurised and that Aura Airlines cannot accommodate Passenger’s with altitude related health problems. The Passenger shall be responsible for disclosing any such health-related problems before boarding which will require medical clearance in writing from their doctor in order to travel.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	Expectant mothers shall require medical clearance from their doctor in order to travel, which shall be evidenced by a doctor’s certificate, obtained not more than one week prior to travel, confirming fitness to travel and stating the following compulsory information: 1. Duration of the pregnancy, 2. Expected delivery date (EDD), 3. Blood pressure.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	The Passenger hereby acknowledges that all services of Aura Airlines are entirely at the Passengers own risk. Aura Airlines shall not be responsible for any direct/indirect losses arising from including but not limited to pregnancy, travel sickness, altitude health related problems and any other health condition inherent to the Passenger.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-6">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>BAGGAGE</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	Baggage:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>i.	EC 120 (4 seater) – the maximum weight including passengers should be 350kg.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px', paddingLeft: '20px'}}>ii.	EC 130 B4 (6 seater) – the maximum weight including passengers should be 450 kg.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>Any checked-in baggage exceeding weight allowance is strictly prohibited.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	It is the objective of Aura Airlines to always to carry Baggage along with the Passenger in the same flight. However, there may be instances when Baggage may have to be sent by a later flight or through secured ground transportation provided by Aura Airlines, due to weight restrictions on the aircraft. In these circumstances Passengers must be aware that Baggage will be delivered at Destination several hours after the flight lands or in the case of departing Passengers, baggage will be collected several hours before, or in some instances the evening prior to, the scheduled departure of the flight. Aura Airlines will not in any circumstance be liable for delay in the transportation of Baggage in the circumstances set out herein. Excess baggage charges may apply.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	Aura Airlines is not responsible for objects of value in any Passenger’s checked-in baggage (including jewelry, antiques, important documents, computer laptops, etc.). These types of articles or any essential medical supplements must be carried on the Aircraft by the Passenger.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	Passengers are prohibited from taking into the aircraft or including in his or her Baggage any flammable liquids and solids, poison, radioactive material, explosives, volatile spirits, firearms, ammunitions, compressed gases, corrosives, lighters or matches, any easily ignitable article, any offensive thing likely to cause inconvenience to passengers, anything likely to endanger the aircraft, persons or property on board or any item prohibited or restricted from being taken on board in terms of applicable laws and regulations.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>5.	Fragile Baggage - Aura Airlines will only undertake to carry fragile baggage at the risk and expense of the Passenger. Whilst Aura Airlines will endeavor to ensure that fragile items are carried with due caution, it shall have no responsibility or liability in case of damage during Carriage.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>6.	We may refuse to accept Baggage as checked baggage unless it is properly packed in suitcases or other similar containers to ensure safe carriage with ordinary care in handling. We may also refuse to accept Baggage which is of unusual size, shape, weight or character.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>7.	The Passenger shall collect his baggage as soon as it is available for collection at places of Destination. The acceptance of Baggage by the bearer of the Baggage Check without complaint at the time of delivery is prima facie evidence that the Baggage has been delivered in good condition and in accordance with the contract of Carriage.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-7">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>SCHEDULES DELAYS AND CANCELLATIONS</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	Aura Airlines reserves the right to effect changes to the timetables between the date of publication and the date of travel. Aura Airlines makes no guarantee pertaining to the timings stated on the published timetable to the Passenger and shall not be liable in any manner to the Passenger on account of delay resulting from any changes or variations to flight schedules, timings or cancellation of flights. Aura Airlines shall nevertheless make all endeavors to provide transportation to the Passenger with due dispatch and as close possible to the schedules and the agreed timings as set out in the Ticket.</p>
                        </div>
                        <div class="tab-pane fade" id="pills-8">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '18px'}}>GENERAL</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>1.	It is possible Aura Airlines may need to change the scheduled flight time subsequent to confirmation of a flight. If the Passenger provides Aura Airlines with contact information, Aura Airlines will endeavor to notify the Passenger of any such changes. Aura Airlines shall not accept any responsibility or liability if such notice is not provided.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>2.	Arrangements for Additional Services – Aura Airlines acts only as the Passenger’s agent, in the event that it makes arrangements for and on behalf of the Passenger or issues a ticket/voucher relating to transportation/services offered by any third party to provide any services other than carriage by air, (i.e. hotel reservations or car rental). The terms and conditions of the third-party service provider will apply to such services.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>3.	In the event Aura Airlines in its absolute discretion provides surface/ground transportation to Passenger, other conditions may apply to such transportation/services, as applicable. Such terms and conditions are available from Aura Airlines upon request.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>4.	Aura Airlines will not carry any animals/birds on the aircraft.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>5.	Aura Airlines reserves the right, without notice, to cancel any flight or to substitute any aircraft for that originally scheduled or to transfer the Passenger without notice to any other aircraft or flight and all the conditions of the Ticket shall remain applicable to such substituted Carriage.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>6.	Aura Airlines may arrange with any person or corporation to undertake the Carriage hereby contracted for any part thereof or any service auxiliary thereto including the transportation of the passenger, his Baggage to and from any aerodrome/airport or taking off or landing place and any such person or corporation and his servants, agents or employees shall be entitled to the benefits of these conditions to the same extent as Aura Airlines.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>7.	Notwithstanding the place of issue of the Ticket or travel documents this contract shall be governed and construed in accordance with the laws of Sri Lanka.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>8.	Passengers shall comply with the instructions of Aura Airlines, its servants or agents concerning all matters connected with or consequential to the air service and shall comply with the conditions of this Ticket and the terms of any notice exhibited in the aircraft. The Passenger shall be responsible for any loss or damage occasioned by non-observance of any instructions, conditions or notice.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>9.	No agent, representative or third party has the authority to alter, modify or waive any provisions of this contract.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Conditions