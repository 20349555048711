import React, { useState, useEffect } from 'react';
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling
        });
    };

    return (
        <>
            <div className="container-fluid bg-footer text-secondary px-5 mt-5">
                <div className="row gx-5">
                    {/* Quick Links */}
                    <div className="col-lg-3 col-md-6 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-dark mb-4">Quick Links</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-secondary text-dark mb-2" to="/" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>Home</Link>
                            <Link className="text-secondary text-dark mb-2" to="/about" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>About Us</Link>
                            <Link className="text-secondary text-dark mb-2" to="/Membership" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>Membership</Link>
                            <Link className="text-secondary text-dark mb-2" to="/Destination" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>Destinations</Link>
                            <Link className="text-secondary text-dark mb-2" to="/Offers" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>Offers</Link>
                            <Link className="text-secondary text-dark" to="/Inquire" onClick={scrollToTop}><i className="bi bi-arrow-right text-dark me-2"></i>Inquire</Link>
                        </div>
                    </div>

                    {/* Newsletter */}
                    <div className="col-lg-3 col-md-6 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-dark mb-4">Subscribe</h4>
                        <h6 className="text-uppercase text-dark">Get in touch for the latest updates.</h6><br />
                        <form action="">
                            <div className="input-group">
                                <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                                <button className="btn btn-dark">Submit</button>
                            </div>
                        </form>
                    </div>

                    {/* Contact */}
                    <div className="col-lg-3 col-md-6 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-dark mb-4">Contact</h4>
                        <div className="d-flex mb-2">
                            <i className="bi bi-geo-alt text-dark me-2"></i>
                            <p className="mb-0 text-dark">Colombo International Airport. Ratmalana, Sri Lanka</p>
                        </div>

                        <div className="d-flex mb-2">
                            <i className="bi bi-telephone text-dark me-2"></i>
                            <p className="mb-0 text-dark">+94 113 500 666</p>
                        </div>

                        <div className="d-flex mb-2">
                            <i className="bi bi-envelope-open text-dark me-2"></i>
                            <p className="mb-0 text-dark">info@auraailines.com</p>
                        </div>
                        
                        <div className="d-flex mb-2">
                            <i className="bi bi-telephone text-dark me-2"></i>
                            <p className="mb-0 text-dark">+94 779 071 437 / +94 765 311 788</p>
                        </div>
                    </div>

                    {/* Follow Us */}
                    <div className="col-lg-3 col-md-6 pt-0 pt-lg-5 mb-5">
                        <h4 className="text-uppercase text-dark mb-4">Follow Us</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-secondary mb-2 text-dark" href="https://www.facebook.com/viranjiththambugala"><i className="fab fa-facebook-f text-dark me-2"></i>Facebook</a>
                            <a className="text-secondary mb-2 text-dark" href="#"><i className="fab fa-instagram text-dark me-2"></i>Instagram</a>
                            <a className="text-secondary mb-2 text-dark" href="https://www.youtube.com/watch?v=KkQATIXBY5w"><i className="fab fa-twitter text-dark me-2"></i>Twitter</a>
                            <a className="text-secondary mb-2 text-dark" href="#"><i className="fas fa-user text-dark me-2"></i>Our Trainers</a>
                            <a className="text-secondary mb-2 text-dark" href="https://www.youtube.com/watch?v=KkQATIXBY5w"><i className="fab fa-youtube text-dark me-2"></i>Youtube</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4 py-lg-0 px-5" style={{ background: '#111111' }}>
                <div className="row gx-5">
                    <div className="col-lg-12">
                        <div className="py-lg-4 text-center" style={{ color: '#dadadae7' }}>
                            <p className="text-secondary mb-0 text-center">Copyright &copy; 2024 <a href="https://auraairline.com/" style={{ fontSize: '18px', fontWeight: 'bold' }}>Aura Airlines</a>. All Right Reserved | <a href="/Policy" target="_blank" style={{ fontSize: '17px', fontWeight: 'bold' }}>Privacy Policy</a> | <a href="/Conditions" target="_blank" style={{ fontSize: '17px', fontWeight: 'bold' }}>Terms and Conditions</a></p>
                        </div>
                    </div>
                </div>
            </div>

            {showButton && (
                <button className="btn btn-primary btn-lg-square back-to-top top-btn" onClick={scrollToTop}>
                    <i className="bi bi-arrow-up"></i>
                </button>
            )}
        </>
    );
}

export default Footer;
