import React from 'react'
import './helicopter.css';

const Helicopter_two = () => {
  return (
    <>

          <div class="container-fluid page-header_helicopter_two"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Helicopter</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Helicopter</h6>
              </div>
          </div>

          <div class="container-fluid py-1">
              <div class="container pt-3 pb-3">
                  <h1 class="display-6 text-uppercase text-center mb-3">AIRBUS HELICOPTER <span class="text-primary">H120 B</span></h1>
                  <div class="row justify-content-center">
                      <div class="col-lg-10 text-center">
                          <img class="w-75 mb-4" src="Airlines/auraairline6.jpg" alt="" />
                          <p class="text-center">The single-engine AIRBUS H120 helicopter, with a seating capacity of 4 passengers plus 1 pilot, embodies excellence in aviation. Boasting an advanced glass cockpit and enhanced safety features such as crashworthiness and energy-absorbing seats, the H120 prioritizes passenger comfort and security. With its agile nature, the H120 is perfectly suited for various missions, including aerial work, corporate transport, and private aviation.</p>
                      </div>
                  </div>
              </div>
          </div>

    </>
  )
}

export default Helicopter_two