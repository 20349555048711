import React, { useState } from 'react';
import Axios from 'axios';
import './Contact.css'
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Contactus = () => {

    const url = "https://auraairline.com/aura_airlines/CustomerContactDetails"

    const [data, setData] = useState({
        cus_name: '',
        email: '',
        mobi_num: '',
        message: ''
    });

    function submit(e){
        
        e.preventDefault();

        Axios.post(url,{
            cus_name:data.cus_name,
            email:data.email,
            mobi_num:data.mobi_num,
            message:data.message
        })
            .then(res=>{
                const responseData = res.data;
                console.log(responseData);

                if (responseData.status === 'SUCCESS') {
                    Swal.fire({
                        title: 'Success',
                        text: 'Thank you for getting in touch! We appreciate you contacting us.',
                    }).then(() => {
                        // Reset the form
                        setData({
                            cus_name:"",
                            email:"",
                            mobi_num:"",
                            message:""
                        });
                    });
                    
                } else {
                    // Handle other response statuses or errors
                    Swal.fire({
                        title: 'Error',
                        text: 'Oops! Somthing went wrong. Please try again.',
                    });
                }

            })
            .catch(error => {
                console.error("An error occurred:", error);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred while processing your request. Please try again later.',
                });
            });
                
        setData({
            cus_name:"",
            email:"",
            mobi_num:"",
            message:""
          });

    }

    function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        /*console.log(newdata)*/

    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behaviour: "smooth"});
    }

    const [phone, setPhone] = useState('');
    const [isValidNumber, setIsValidNumber] = useState(true);

    const handlePhoneChange = (value) => {
        // Check if the input contains only numbers
        const isValid = /^\d+$/.test(value);

        // Check if the number length is between 8 and 15 digits (adjust as needed)
        const isValidLength = value.length >= 11 && value.length <= 15;

        setIsValidNumber(isValid && isValidLength);

        if (isValid && isValidLength) {
            setPhone(value);
        }
    };

    return (
        <>
            <div class="container-fluid page-header_contact"><br /><br /><br />
                <h1 class="display-3 text-uppercase text-white mb-3">Contact / Inquires</h1>
                <div class="d-inline-flex text-white">
                    <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                    <h6 class="text-body m-0 px-3">/</h6>
                    <h6 class="text-uppercase text-body m-0">Contact & Inquire</h6>
                </div>
            </div>

            <div class="container-xxl py-0">
                <div class="container">
                    {/* <div class="section_title_div text-center position-relative pb-3 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                        <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                        <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                    </div> */}
                    <div class="row g-5">


                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <h4 class="mb-2">Reservations</h4>
                            <form id="contactForm" onSubmit={(e)=> submit(e)}>
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" id="cus_name" placeholder="Your Name" onChange={(e)=>handle(e)} value={data.cus_name} />
                                                <label for="name">Type your name here*</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-floating">
                                            <input type="email" class="form-control" id="email" placeholder="Your Email" onChange={(e)=>handle(e)} value={data.email} />
                                                <label for="email">Type your E-mail address here*</label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-floatings">
                                            <PhoneInput
                                                country={'lk'}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                inputProps={{
                                                    name: 'mobi_num',
                                                    required: true,
                                                    autoFocus: true,
                                                    className: `form-control ${!isValidNumber ? 'is-invalid' : ''}`, // Add 'is-invalid' class if validation fails
                                                    placeholder: 'Contact Number'
                                                }}
                                                containerStyle={{ width: '100%' }}
                                                inputStyle={{ width: '100%' }}
                                            />
                                            {!isValidNumber && (
                                                <div className="invalid-feedback">
                                                    Please enter a valid phone number (8-15 digits).
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    

                                    {/* <div class="col-12">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" placeholder="Contact Number" onChange={(e) => handle(e)} id="mobi_num" value={data.mobi_num} />
                                            <label for="subject">Type your contact number here*</label>
                                        </div>
                                    </div>  */}
                                    <div class="col-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a message here" onChange={(e)=>handle(e)} id="message" value={data.message} style={{ height: '100px' }}></textarea>
                                            <label for="message">Type your message here*</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="terms" name="terms" required />
                                            <label className="form-check-label" htmlFor="terms" style={{ fontSize: '16px', fontWeight: 'bold', color: '#6c6c6c' }}>
                                                I acknowledge that I have carefully read and understood the 
                                                <Link onClick={scrollToTop} to="/Conditions" style={{ color: '#1c61f7' }}> Terms </Link>
                                                outlined in the   
                                                <Link onClick={scrollToTop} to="/Policy" style={{ color: '#1c61f7' }}> Privacy Policy </Link>
                                                of this website, and I hereby agree to abide by its provisions and conditions.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button class="btn btn-primary w-100 py-3" type="submit" id="contactBtn" value="S E N D  M E S S A G E">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                        <h4 class="mb-2">Contact Us</h4>
                            <div class="d-flex flex-column justify-content-between h-120">
                                <div class="bg-lightz d-flex align-items-center w-100 p-2 mb-2">
                                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-darkz" style={{ width: '55px', height: '55px' }}>
                                        <i class="fa fa-map-marker-alt text-primary"></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="mb-2">Visit our office</p>
                                        <h5 class="mb-0">Aura Airlines (Pvt) Ltd, Colombo International Airport. Ratmalana, Sri Lanka</h5>
                                    </div>
                                </div>
                                <div class="bg-lightz d-flex align-items-center w-100 p-2 mb-2">
                                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-darkz" style={{ width: '55px', height: '55px' }}>
                                        <i class="fa fa-phone-alt text-primary"></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="mb-2">Call Us Now</p>
                                        <h5 class="mb-0">+94 113 500 666</h5>
                                    </div>
                                </div>
                                <div class="bg-lightz d-flex align-items-center w-100 p-2 mb-2">
                                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-darkz" style={{ width: '55px', height: '55px' }}>
                                        <i class="fa fa-phone-alt text-primary"></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="mb-2">Fax</p>
                                        <h5 class="mb-0">0112180192</h5>
                                    </div>
                                </div>
                                <div class="bg-lightz d-flex align-items-center w-100 p-2 mb-2">
                                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-darkz" style={{ width: '55px', height: '55px' }}>
                                        <i class="fa fa-envelope-open text-primary"></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="mb-2">Mail Us Now</p>
                                        <h5 class="mb-0">info@auraailines.com</h5>
                                    </div>
                                </div>
                                <div class="bg-lightz d-flex align-items-center w-100 p-2">
                                    <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-darkz" style={{ width: '55px', height: '55px' }}>
                                        <i class="fa fa-phone-alt text-primary"></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="mb-2">Mobile</p>
                                        <h5 class="mb-0">+94 779 071 437 / +94 765 311 788</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
      
                    </div>
                </div>
            </div>

            <br />

            {/* <div class="container-fluid wow fadeInUp background" data-wow-delay="0.1s">
                <div class="container py-5">
                    <div class="section_title_div text-center position-relative pb-3 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                        <h5 class="fw-bold text-primary text-uppercase">Contact Us</h5>
                        <h1 class="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                    </div>
                   
                    <div class="row g-0">
                        <div class="col-lg-7 wow slideInUp" data-wow-delay="0.3s">
                            <form id="contactForm" onSubmit={(e)=> submit(e)}>
                                <div class="row g-3">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 bg-light px-4" placeholder="Your Name" onChange={(e)=>handle(e)} id="cus_name" value={data.cus_name} style={{ height: '55px' }} />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" class="form-control border-0 bg-light px-4" placeholder="Your Email" onChange={(e)=>handle(e)} id="email" value={data.email} style={{ height: '55px' }} />
                                    </div>
                                    <div class="col-12">
                                        <input type="text" class="form-control border-0 bg-light px-4" placeholder="Contact Number" onChange={(e)=>handle(e)} id="mobi_num" value={data.mobi_num} style={{ height: '55px' }} />
                                    </div>
                                    <div class="col-12">
                                        <textarea class="form-control border-0 bg-light px-4 py-3" rows="4" onChange={(e)=>handle(e)} id="message" value={data.message} placeholder="Message"></textarea>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="terms" name="terms" required />
                                            <label className="form-check-label" htmlFor="terms" style={{ fontSize: '16px', fontWeight: 'bold', color: '#6c6c6c' }}>
                                                I acknowledge that I have carefully read and understood the terms outlined in the Privacy Policy of this website, and I hereby agree to abide by its provisions and conditions.
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <button class="btn btn-primary w-100 py-3" id="contactBtn" value="S E N D  M E S S A G E" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="col-lg-5 mb-4">
                            <div class="bg-secondary-contact d-flex flex-column justify-content-center px-5 mb-4" style={{ height: '440px' }}>
                                <div class="d-flex mb-3">
                                    <i class="fa fa-2x fa-phone-alt text-primary flex-shrink-0 mr-3"></i>
                                    <div class="mt-n1">
                                        <h5>Call to ask any question</h5>
                                        <p>+94 77 907 1437</p>
                                    </div>
                                </div>
                                <div class="d-flex mb-4">
                                    <i class="fa fa-2x fa-phone-alt text-primary flex-shrink-0 mr-3"></i>
                                    <div class="mt-n1">
                                        <h5>Call to ask any question</h5>
                                        <p>+94 113 500 666</p>
                                    </div>
                                </div>
                                <div class="d-flex mb-4">
                                    <i class="fa fa-2x fa-map-marker-alt text-primary flex-shrink-0 mr-3"></i>
                                    <div class="mt-n1">
                                        <h5>Email to get free quote</h5>
                                        <p>info@auraailines.com</p>
                                    </div>
                                </div>
                                <div class="d-flex mb-4">
                                    <i class="fa fa-2x fa-envelope-open text-primary flex-shrink-0 mr-3"></i>
                                    <div class="mt-n1">
                                        <h5>Visit our office</h5>
                                        <p>Aura Airlines (Pvt) Ltd, Colombo International Airport. Ratmalana, Sri Lanka</p>
                                    </div>
                                </div>
                                

                                <div class="d-flex mb-4">
                                    <Link onClick={scrollToTop} class="btn btn-primary w-50 py-3" to="/Policy" style={{ marginRight: '10px' }}>Privacy Policy</Link>
                                    <Link onClick={scrollToTop} to="/Conditions" class="btn btn-secondary w-50 py-3" type="button">Terms & Conditions</Link>
                                </div>

                                
                            </div>
                        </div>

                        
                    </div>

                    <div class="col-lg-12 wow slideInUp" data-wow-delay="0.6s">
                            <iframe class="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.5781096281066!2d79.88604457478648!3d6.821054719634455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25ace5672d713%3A0x19455164f827004b!2sColombo%20International%20Airport%20Ratmalana!5e0!3m2!1sen!2slk!4v1695010005347!5m2!1sen!2slk"
                                frameborder="0" style={{ minHeight: '350px', border: '0' }} allowfullscreen="" aria-hidden="false"
                                tabindex="0"></iframe>
                    </div>

                </div>
            </div> */}

        </>
    )
}

export default Contactus