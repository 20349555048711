import React from 'react'
import './news.css'

const News = () => {
  return (
    <>
          <div class="container-fluid page-header_news"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">News</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">News</h6>
              </div>
          </div>

          {/* <div class="container-fluid py-3">
              <div class="container pb-3">
                  <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                      <h1 class="display-6">SKYDIVE</h1>
                      <h1 class="display-6">WITH</h1>
                      <h1 class="display-6">AIRLINES</h1>
                  
                  </div>
                  <div class="row justify-content-center pt-3">
                      <div class="col-lg-10 text-center">
                          <p class="text-center" style={{ fontSize: '18px' }}>Feel the exhilarating wind tousling your hair, the pulse of adrenaline coursing through your veins, and behold the breathtaking splendor of Sri Lanka from thousands of feet above, overlooking the swaying palm trees and pristine beaches. Get ready, because Skydive Sri Lanka is on the horizon. With a fresh brand, a dynamic team, and unparalleled experiences, get ready to soar and witness the same awe-inspiring vistas over this enchanting island. Our dedicated crew is meticulously refining every aspect to ensure that we are prepared to embark on this exhilarating journey very, very soon.</p>
                      </div>
                      
                  </div>

              </div>
          </div> */}

          <div
              class="container-xxl bg-primary visiting-hours my-5 py-5 wow fadeInUp"
              data-wow-delay="0.1s"
          >
              <div class="container py-2">
                  <div class="row g-5">
                      <div class="col-md-12 wow fadeIn" data-wow-delay="0.3s">
                          <div class="text-center text-white wow fadeInUp" data-wow-delay="0.1s">
                              <h1 class="display-6 text-white">SKYDIVE</h1>
                              <h1 class="display-6 text-white">WITH</h1>
                              <h1 class="display-6 text-white">AURA AIRLINES</h1>
                          </div>

                          <div class="row justify-content-center pt-3">
                              <div class="col-lg-10 text-center">
                                  <p class="text-center" style={{ fontSize: '20px', color: '#cfcfcf' }}>Feel the exhilarating wind tousling your hair, the pulse of adrenaline coursing through your
                                      veins, and behold the breathtaking splendor of Sri Lanka from thousands of feet above,
                                      overlooking the swaying palm trees and pristine beaches. Get ready, because Skydive Sri
                                      Lanka is on the horizon. With a fresh brand, a dynamic team, and unparalleled experiences,
                                      get ready to soar and witness the same awe-inspiring vistas over this enchanting island. Our
                                      dedicated crew is meticulously refining every aspect to ensure that we are prepared to
                                      embark on this exhilarating journey very, very soon.</p>
                              </div>

                          </div>

                      </div>

                  </div>
              </div>
          </div>
   

          {/* <div class="container-fluid py-3 wow animated slideInUp" data-wow-delay="0.1s">
              <div class="container py-4">
        
                  <div class="row">
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.3s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Helicopter (3).jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala3.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.9s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala4.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  
                  <div class="row py-4">
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.3s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Helicopter (3).jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.6s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala3.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow animated zoomIn" data-wow-delay="0.9s">
                          <div class="blog-item bg-light rounded overflow-hidden">
                              <div class="blog-img position-relative overflow-hidden">
                                  <img class="img-fluid" src="images/Yala4.jpg" alt="" />
                                  <a class="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4" href="">Package</a>
                              </div>
                              <div class="p-4">
                                  <div class="d-flex mb-3">
                                      <small><i class="far fa-calendar-alt text-primary me-2"></i>01 Jan, 2023</small>
                                  </div>
                                  <h4 class="mb-3">Annnocuced our new package</h4>
                                  <p>Dolor et eos labore stet justo sed est sed sed sed dolor stet amet</p>
                                  <a class="text-uppercase" href="">Read More <i class="bi bi-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>  */}

    </>
  )
}

export default News