import React from 'react'
import './helicopter.css';

const Helicopter_one = () => {
  return (
    <>

          <div class="container-fluid page-header_helicopter_one"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Helicopter</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Helicopter</h6>
              </div>
          </div>

          <div class="container-fluid py-1">
              <div class="container pt-3 pb-3">
                  <h1 class="display-6 text-uppercase text-center mb-3">AIRBUS HELICOPTER <span class="text-primary">H130 B4</span></h1>
                  <div class="row justify-content-center">
                      <div class="col-lg-10 text-center">
                          <img class="w-75 mb-4" src="Airlines/auraairline3.jpg" alt="" />
                          <p class="text-center">The single-engine AIRBUS H130 helicopter stands out as a premier choice in Sri Lanka, accommodating up to 6 passengers along with 1 pilot. Featuring an advanced glass cockpit equipped with modern navigation and communication systems, the H130 ensures a seamless flying experience. Its spacious and comfortable cabin offers unparalleled visibility, making it ideal for sightseeing and aerial tours. From passenger transport to utility operations, the H130 is renowned for its versatility and reliability.</p>
                      </div>
                  </div>
              </div>
          </div>

    </>
  )
}

export default Helicopter_one