import React, { useEffect } from 'react';
import "./header.css"
import "./bootstrap.min.css"
import "./main.js"
import "./animate.min.css"
import $ from 'jquery'; // Import jQuery
import { Link } from "react-router-dom";

import WOW from 'wowjs';
import 'animate.css';

const Header = () => {

    useEffect(() => {

      const wow = new WOW.WOW();
    wow.init();

        // Add the provided jQuery code here
        $(window).scroll(function () {
          if ($(window).width() < 992) {
            if ($(this).scrollTop() > 45) {
              $('.fixed-top').addClass('bg-dark shadow');
            } else {
              $('.fixed-top').removeClass('bg-dark shadow');
            }
          } else {
            if ($(this).scrollTop() > 45) {
              $('.fixed-top').addClass('bg-dark shadow').css('top', -45);
            } else {
              $('.fixed-top').removeClass('bg-dark shadow').css('top', 0);
            }
          }
        });
      }, []); 

      const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behaviour: "smooth"});
      }

  return (
    <>
     
     <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
        <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            {/* <div className="col-lg-6 px-5 text-start">
                <small style={{ fontSize: "16px", color: "#fff"}} className="ms-4"><i className="fa fa-phone me-2 topbaricons"></i>+0113500666</small>
                <small className="ms-4" style={{ fontSize: "16px", color: "#fff"}}><i className="fa fa-envelope me-2 topbaricons"></i>info@auraairline.com</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
                <small style={{ fontSize: "16px", color: "#fff"}}>Follow us:</small>
                <a className="ms-3" href="https://www.facebook.com/viranjiththambugala"><i className="fab fa-facebook-f"></i></a>
                <a className="ms-3" href="/"><i className="fab fa-twitter"></i></a>
                <a className="ms-3" href="https://www.youtube.com/channel/UCYXgAIF4A6Tm5-kzKR_UbDA"><i className="fab fa-youtube"></i></a>
                <a className="ms-3" href="/"><i className="fab fa-instagram"></i></a>
            </div> */}
          <div className="col-lg-6"></div>
          <div className="col-lg-6 px-5 text-end">
            <a style={{ fontSize: "18px", color: "#fff" }} href="/login" className="text-white">Login</a>
            <a style={{ fontSize: "18px", color: "#fff" }} href="/signup" className="text-white ms-3">|</a>
            <a style={{ fontSize: "18px", color: "#fff" }} href="/signup" className="text-white ms-3">Sign Up</a>
            <small className="ms-3" style={{ fontSize: "16px", color: "#fff" }}><i className="fa fa-phone me-2 topbaricons"></i>+0113500666</small>
            <small className="ms-3" style={{ fontSize: "16px", color: "#fff" }}><i className="fa fa-envelope me-2 topbaricons"></i>info@auraairline.com</small>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="logo">
                <Link onClick={scrollToTop} to="/">
                    <img src="airlogo.png" alt="Logo" className="logo-img"></img>
                </Link>
            </div>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <Link onClick={scrollToTop} to="/" className="nav-item nav-link active">Home</Link>
                    <Link onClick={scrollToTop} to="/about" className="nav-item nav-link">About Us</Link>
                    <Link onClick={scrollToTop} to="/News" className="nav-item nav-link">News</Link>
                    <Link onClick={scrollToTop} to="/Inquire" className="nav-item nav-link">Contact Us</Link>
                    {/* <Link to="/Inquire" className="nav-item nav-link">Book Now</Link> */}
                    {/* <Link to="/Membership" className="nav-item nav-link">Membership</Link>
                    <Link to="/Destination" className="nav-item nav-link">Destinations</Link>
                    <Link to="/Offers" className="nav-item nav-link">Offers</Link> */}
                    {/* <Link onClick={scrollToTop} to="/Login" className="nav-item nav-link">Login</Link> */}
                    {/* <Link className="nav-item nav-link">Login</Link> */}
                    
                </div>
                <div className="d-none d-lg-flex ms-2">
                    <Link onClick={scrollToTop} className="btn btn-primary-header py-2 px-3" to="/#booknowform">
                        Book Now
                        <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                            <i className="fa fa-arrow-right"></i>
                        </div>
                    </Link>
                </div>
            </div>
        </nav>
    </div>

   
    </>
  )
}

export default Header;
