import React from 'react'
import "./policy.css";

const Policy = () => {

  return (
    <>
    
    <div class="container-fluid page-header_booking">
        <h1 class="display-3 text-uppercase text-white mb-3">PRIVACY POLICY</h1>
        <div class="d-inline-flex text-white">
            <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
            <h6 class="text-body m-0 px-3">/</h6>
            <h6 class="text-uppercase text-body m-0">PRIVACY POLICY</h6>
        </div>
    </div>

    <div class="condition">
        <div class="row gx-5">
            
            <div class="col-lg-12">
               
                <div class="rounded glassmorphism-conditions p-5">
                  
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-1">
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Privacy Statement</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>At Aura Airlines, we are committed to protecting your privacy. This Privacy Statement explains how we collect, use, disclose, and safeguard your personal information when you visit our website or use our services. Please read this Privacy Statement carefully. By accessing our website or using our services, you consent to the collection, use, disclosure, and safeguarding of your information as described in this Privacy Statement.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Information We Collect</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>We may collect personal information from you when you visit our website, make a booking, or interact with us through other means. This information may include:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Contact information such as your name, email address, phone number, and mailing address.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span> Payment information such as credit card details.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Travel preferences and special requests.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Information you provide when you communicate with us or participate in surveys, promotions, or contests.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>How We Use Your Information</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>We use the information we collect for various purposes, including:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Providing and managing our services, including processing bookings and payments.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Communicating with you regarding your bookings, inquiries, and updates about our services.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Personalizing your experience and improving our website and services.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Conducting research and analysis to better understand our customers' needs and preferences.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Complying with legal and regulatory requirements.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	Disclosure of Your Information</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>We may disclose your personal information to third parties in the following circumstances:</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	To our trusted service providers who assist us in operating our website and providing our services.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	To comply with legal obligations or respond to lawful requests from governmental authorities.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	To protect the rights, property, or safety of Aura Airlines, our customers, or others.</p>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}><span style={{ fontSize: '28px'}}>&#8226;</span>	We do not sell or rent your personal information to third parties for marketing purposes.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Security of Your Information</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>We take reasonable measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Your Choices and Rights</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>You have the right to access, update, or delete your personal information. You may also opt out of receiving promotional communications from us by following the instructions provided in such communications. Please note that even if you opt out of receiving promotional messages, we may still send you non-promotional communications, such as those related to your bookings or account.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Updates to this Privacy Statement</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>We may update this Privacy Statement from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Statement on our website.</p>
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Contact Us</h5>
                            <p class="text-secondary mb-2" style={{ fontSize: '18px'}}>If you have any questions or concerns about this Privacy Statement or our privacy practices, please contact us at info@auraairline.com</p>

                            <br />
                            <h5 class="mb-0 p-2" style={{ color: 'aliceblue', fontSize: '20px'}}>Last Updated: March 2024</h5>


                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Policy