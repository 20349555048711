import React from 'react'
import Contactus from './Contactus'
// import Newsletter from './Newsletter'

const MainContact = () => {
  return (
    <>
        <Contactus /> 
        {/* <Newsletter />  */}
    </>
  )
}

export default MainContact