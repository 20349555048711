import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Layout from '../containers/Layout'
import Air from './Components/Air/Air';
// import Navbar from './Components/Navbar/Navbar';
// import Footer from './Footer';
// import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import Header from './Components/Common/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Common/Footer/footer';
import Aboutus from './Components/About/Aboutus';
import Reservation from './Components/Book/Reservation';
import Membership_page from './Components/Membership/Membership_page';
// import Contactus from './Components/Contact/Contactus';
import Offers_page from './Components/Offer/Offers_page';
import Destination_page from './Components/Destination/Destination_page';
import MainContact from './Components/Contact/MainContact';
import Conditions from './Components/Conditions/Conditions';
import News from './Components/News/News';
import Login from './Components/Login/Login';
import Profile_one from './Components/Profile/Profile_one';
import Profile_two from './Components/Profile/Profile_two';
import Profile_three from './Components/Profile/Profile_three';
import Profile_four from './Components/Profile/Profile_four';
import Helicopter_one from './Components/Helicopter/Helicopter_one';
import Helicopter_two from './Components/Helicopter/Helicopter_two';
import Policy from './Components/Policy/Policy';
import Helicopter_main from './Components/Helicopter/Helicopter_main';


function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Main/>}/>
          <Route exact path="/Air" element={<Air/>}/>
          <Route exact path="/Book" element={<Reservation/>}/>
          <Route exact path="/Contact" element={<Contact/>}/>
          <Route exact path="/Offers" element={<Offers_page/>}/>
          <Route exact path='/About' element={<Aboutus/>}/>
          <Route exact path='/Membership' element={<Membership_page/>}/>
          <Route exact path='/Destination' element={<Destination_page/>}/>
          <Route exact path='/Inquire' element={<MainContact/>}/>
          <Route exact path='/Conditions' element={<Conditions/>}/>
          <Route exact path='/News' element={<News/>}/>
          <Route exact path='/Login' element={<Login/>}/>
          <Route exact path='/Profile_one' element={<Profile_one/>}/>
          <Route exact path='/Profile_two' element={<Profile_two/>}/>
          <Route exact path='/Profile_three' element={<Profile_three/>}/>
          <Route exact path='/Profile_four' element={<Profile_four/>}/>
          <Route exact path='/Helicopter_one' element={<Helicopter_one/>}/>
          <Route exact path='/Helicopter_two' element={<Helicopter_two/>}/>
          <Route exact path='/Policy' element={<Policy/>}/>
          <Route exact path='/Helicopter' element={<Helicopter_main/>}/>
        </Routes>
        <Footer />
        
      </Router>
    </>
  );
}

export default App;
