import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Destination.css'

const Destination_page = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <> 
            <div class="container-fluid page-header_destination">
                <br /><br /><br /> <br />
                <h1 class="display-3 text-uppercase text-white mb-0" style={{ textAlign: 'center' }} >Fly with Us to</h1>
                <h1 class="display-3 text-uppercase text-white mb-0" style={{ textAlign: 'center' }} >Amazing Destinations</h1>
                <div class="d-inline-flex text-white">
                    <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                    <h6 class="text-body m-0 px-3">/</h6>
                    <h6 class="text-uppercase text-body m-0">Destination</h6>
                </div>
            </div>

            {/* North and Northeast */}

            <div class="container-xxl py-6">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="row img-twice position-relative h-100">
                                <div class="col-6">
                                    <img class="img-fluid rounded" src="img/project-9.jpg" alt="" />
                                </div>
                                <div class="col-6 align-self-end">
                                    <img class="img-fluid rounded" src="img/project-6.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="h-100">
                                <h1 class="display-6 mb-4">North and Northeast</h1>
                                <p>Sri Lanka, nestled in the heart of the Indian Ocean, boasts a treasure trove of tourist
                                    destinations, each offering unique experiences. The Northern and Northeastern regions,
                                    often overlooked by tourists due to their tumultuous past, are now emerging as
                                    captivating destinations, blending history, culture, and natural beauty in equal measure.</p>

                                <p>Discover the enchanting regions of North and Northeast Sri Lanka, where history,
                                    culture, and natural beauty converge to create unforgettable experiences. Jaffna, the
                                    cultural capital, captivates with its resilient charm, from the historic Jaffna Fort and the
                                    revered Nallur Kandaswamy Kovil to the bustling Jaffna Market and serene Keerimalai
                                    Springs. Mannar, an island city rich in mythology, invites birdwatchers and nature lovers
                                    to explore the mysterious Adam's Bridge, the ancient Baobab Tree, and the vibrant
                                    Mannar Bird Sanctuary. Trincomalee, nestled along the Eastern coast, offers a
                                    symphony of sun, sand, and sea with the sacred Koneswaram Kovil, historic Fort
                                    Frederick, and idyllic beaches of Uppuveli and Nilaveli. Pigeon Island National Park's
                                    underwater wonders beckon snorkelers and eco-tourists alike. Batticaloa, known as the
                                    'Land of the Singing Fish,' delights with the iconic Dutch Fort, picturesque Kallady
                                    Bridge, and bustling Batticaloa Market, all celebrating the region's rich heritage. Venture
                                    beyond the tourist trail and immerse yourself in the timeless allure of Sri Lanka's North
                                    and Northeast, where each destination promises a unique and memorable journey.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl project py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-2 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: '600px' }}>
                        <h1 class="display-6 mb-2">Visit Places in North and Northeast</h1>
                        <p class="text-center">In conclusion, the Northern and Northeastern regions of Sri Lanka, though often
                            overshadowed by their more popular counterparts, offer a wealth of experiences for
                            intrepid travellers seeking to explore off-the-beaten-path destinations. From ancient
                            temples to pristine beaches, from colonial forts to natural wonders, these regions beckon
                            with their timeless allure and promise unforgettable memories for those willing to venture
                            beyond the tourist trail. </p>
                    </div>
                    <div class="row wow fadeInUp" data-wow-delay="0.3s">
                        <div class="col-lg-3">
                            <div class="nav nav-pillsz d-flex justify-content-between w-100 h-100 me-4">
                                <button class="nav-links w-100 d-flex align-items-center text-start p-4 mb-4 active" data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                                    <h3 class="m-0">01. Jaffna</h3>
                                </button>
                                <button class="nav-links w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                                    <h3 class="m-0">02. Mannar</h3>
                                </button>
                                <button class="nav-links w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                                    <h3 class="m-0">03. Trincomalee</h3>
                                </button>
                                <button class="nav-links w-100 d-flex align-items-center text-start p-4 mb-0" data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                                    <h3 class="m-0">04. Batticaloa</h3>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="tab-content w-100">
                                <div class="tab-pane fade show active" id="tab-pane-1">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/project-1.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="mb-4">Jaffna, the cultural capital of the North, exudes an aura of resilience and charm. Steeped
                                                in history, it bears the scars of a tumultuous past while proudly showcasing its rich
                                                heritage. The iconic Jaffna Fort, standing as a sentinel of bygone eras, narrates tales of
                                                colonial conquests and struggles for independence. Nearby, the Jaffna Public Library
                                                stands as a testament to the region's thirst for knowledge and cultural revival. </p>
                                                
                                            <p class="mb-4">The Nallur Kandaswamy Kovil, a majestic Hindu temple adorned with intricate carvings
                                                and vibrant sculptures, is a sacred pilgrimage site and a symbol of religious tolerance.
                                                The bustling Jaffna Market offers a sensory delight, with its colourful array of fresh
                                                produce, aromatic spices, and traditional handicrafts.
                                            </p>

                                            <p class="mb-4">For nature enthusiasts, the Delft Island beckons with its untamed beauty and wild horses
                                                roaming freely across its rugged terrain. The Keerimalai Springs, revered for their healing
                                                properties, provide a serene sanctuary for rejuvenation amidst tranquil surroundings.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-2">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/project-2.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="mb-4">Mannar, an island city steeped in mythology and history, is a haven for birdwatchers and
                                                nature enthusiasts. The Adam's Bridge, a natural causeway shrouded in mystery and
                                                legend, connects Mannar to the Indian subcontinent, inviting exploration and discovery.</p>
                                                
                                            <p class="mb-4">The Baobab Tree, a botanical wonder believed to have been brought by Arab traders
                                                centuries ago, stands as a silent sentinel amidst the shifting sands of time. The Mannar
                                                Fort, a relic of colonial conquests, offers panoramic views of the surrounding landscape
                                                and a glimpse into the region's storied past.
                                            </p>

                                            <p class="mb-4">The Mannar Bird Sanctuary, a paradise for migratory birds, attracts avian enthusiasts
                                                from around the globe with its diverse avifauna and serene lagoons. The Talaimannar Pier,
                                                stretching out into the azure waters of the Palk Strait, is a poignant reminder of the
                                                region's maritime heritage and its role as a crossroads of civilizations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-3">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/project-3.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="mb-4">Trincomalee, nestled along the pristine shores of the Eastern coast, is a symphony of sun,
                                                sand, and sea. The iconic Koneswaram Kovil, perched atop Swami Rock overlooking the
                                                azure waters below, is a sacred pilgrimage site and a testament to the region's spiritual
                                                heritage.</p>
                                                
                                            <p class="mb-4">The Fort Frederick, a colonial bastion steeped in history, offers panoramic views of the
                                                bustling harbour and the surrounding coastline. Nearby, the Hot Springs of Kanniya
                                                beckon with their therapeutic waters, providing a soothing retreat for weary travellers.
                                            </p>

                                            <p class="mb-4">Uppuveli and Nilaveli, with their powder-white beaches and crystalline waters, offer
                                                idyllic settings for sunbathing, swimming, and snorkelling. Pigeon Island National Park, a
                                                marine sanctuary teeming with vibrant coral reefs and exotic marine life, is a paradise for
                                                underwater exploration and eco-tourism.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-4">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/project-4.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="mb-4">Batticaloa, known as the 'Land of the Singing Fish,' is a tapestry of culture, cuisine, and
                                                craftsmanship. The iconic Dutch Fort, with its imposing ramparts and ancient
                                                battlements, stands as a sentinel of bygone eras and a testament to the region's colonial
                                                past. </p>

                                            <p class="mb-4">The Kallady Bridge, spanning the tranquil waters of the lagoon, offers breathtaking views
                                                of the surrounding landscape and a glimpse into the region's maritime heritage. The
                                                Batticaloa Lighthouse, standing tall against the backdrop of the azure sky, is a beacon of
                                                hope and resilience for the local community.
                                            </p>

                                            <p class="mb-4">The Batticaloa Market, a bustling hub of activity and commerce, offers a sensory delight
                                                with its colourful array of fresh seafood, aromatic spices, and traditional handicrafts.
                                                Nearby, the Kattankudy Heritage Museum showcases the rich cultural tapestry of the
                                                region, celebrating its diverse heritage and traditions.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          <div id="heritage" style={{ display: 'none' }}></div>

            {/* Mannar */}

            <div class="container-xxl py-6">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="h-100">
                                <h1 class="display-6 mb-4">Cultural Heritage Escapade: Delving into Sri Lanka's Ancient Splendour </h1>
                                <p>Experience the unparalleled beauty and cultural richness of Sri Lanka's top destinations.
                                    Sigiriya's Lion Rock, a UNESCO World Heritage Site, offers breathtaking views and
                                    historical intrigue. Kandy's Temple of the Sacred Tooth Relic, a spiritual epicenter,
                                    captivates with its sacred Buddhist artifact and vibrant Esala Perahera festival. Dambulla
                                    Cave Temple enchants with its ancient murals and serene Buddha statues.
                                    Polonnaruwa's archaeological site unveils grand palaces and intricate sculptures, while
                                    Galle Fort showcases colonial charm and maritime history</p>

                                <p>Anuradhapura, one of the
                                    world's oldest cities, features sacred stupas and the revered Sri Maha Bodhi tree. Adam's
                                    Peak, a pilgrimage site, provides a spiritual journey with its multi-religious significance
                                    and stunning sunrise views. Kandy's serene landscapes and cultural festivities, along
                                    with Ella's lush tea plantations and hiking trails, offer idyllic retreats. Each location, from
                                    the storied ruins of Polonnaruwa to the scenic beauty of Ella, invites visitors to immerse
                                    themselves in Sri Lanka's diverse heritage and natural wonders.
                                </p>

                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="row img-twice position-relative h-100">
                                <div class="col-6">
                                    <img class="img-fluid rounded" src="img/home_13.webp" alt="" />
                                </div>
                                <div class="col-6 align-self-end">
                                    <img class="img-fluid rounded" src="img/home_1.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid about py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-2 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: '600px' }}>
                        <h1 class="display-6 mb-2">Visit Places in Heritage</h1>
                    </div>
                    <div class="tab-class text-center">
                        <ul class="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase border-inner p-4 mb-4">
                            <li class="nav-item">
                                <a class="nav-link text-white active" data-bs-toggle="pill" href="#tab-1">Sigiriya</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-2">Kandy</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-3">Dambulla Temple</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-4">Polonnaruwa Site</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-5">Galle Fort</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-6">Anuradhapura Site</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-7">Adam's Peak (Sri Pada)</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-8">Kandy Sacred City</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-white" data-bs-toggle="pill" href="#tab-9">Ella</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div id="tab-1" class="tab-pane fade show p-0 active">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span>Sigiriya, also known as the Lion Rock, is a UNESCO World Heritage Site that captivates
                                                    visitors with its breathtaking natural beauty and historical significance. Rising
                                                    majestically from the surrounding plains, this ancient rock fortress stands as a testament
                                                    to Sri Lanka's rich cultural heritage and architectural prowess.The site dates back over
                                                    1500 years, originally serving as a monastery before being transformed into a royal
                                                    residence by King Kasyapa.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span> The highlight of Sigiriya is the iconic Lion Gate, flanked by
                                                    colossal lion paws, which once guarded the entrance to the palace atop the rock. Visitors
                                                    can ascend the rock via a series of steep staircases, passing through stunning frescoes
                                                    depicting celestial maidens, and reaching the summit to marvel at panoramic views of
                                                    the surrounding landscape. Sigiriya's fusion of natural beauty, architectural grandeur,
                                                    and historical significance makes it a must-visit destination for travellers to Sri Lanka.</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="tab-2" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>The Temple of the Tooth, located in the picturesque city of Kandy, houses the sacred tooth
                                                    relic of the Buddha, making it one of the most revered religious sites in Sri Lanka. This
                                                    architectural marvel, with its intricate carvings and ornate decorations, attracts pilgrims
                                                    and tourists alike, seeking blessings and spiritual solace. The temple complex, known as
                                                    the Sri Dalada Maligawa, is situated amidst lush gardens and tranquil surroundings,
                                                    providing a serene sanctuary for worship and reflection. Visitors can witness daily rituals
                                                    such as the offering of floral tributes and the chanting of prayers, as well as special
                                                    ceremonies and processions during festivals like Esala Perahera. The Temple of the Tooth
                                                    is not only a place of worship but also a living symbol of Sri Lanka's Buddhist heritage and cultural identity, drawing visitors from around the world to experience its timeless
                                                    spirituality and profound significance.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-3" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>The Dambulla Cave Temple, nestled amidst lush greenery in the central province of Sri
                                                    Lanka, is renowned for its stunning cave complex adorned with ancient murals and
                                                    exquisite Buddhist statues. Dating back to the 1st century BCE, this UNESCO-listed site
                                                    offers a glimpse into the island's rich cultural heritage and spiritual traditions. The temple
                                                    complex comprises five caves carved into the rock face, each adorned with elaborate
                                                    frescoes depicting scenes from Buddhist mythology and life-size statues of the Buddha
                                                    and other deities. The highlight of the site is the towering Golden Buddha statue, which
                                                    dominates the main cave and exudes a sense of timeless serenity. Visitors can explore
                                                    the labyrinthine caves, marvel at the intricate artwork, and soak in the spiritual
                                                    atmosphere that pervades the site. The Dambulla Cave Temple is not only a testament to
                                                    Sri Lanka's ancient artistic achievements but also a sacred pilgrimage site that continues
                                                    to inspire devotion and reverence among believers and visitors alike. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-4" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Polonnaruwa, the ancient capital of Sri Lanka, is a treasure trove of archaeological
                                                    wonders, including grand palaces, intricately carved statues, and majestic stupas. This
                                                    UNESCO World Heritage Site offers visitors a fascinating journey back in time, exploring
                                                    the ruins of a bygone era and unravelling the mysteries of a once-thriving civilization.
                                                    Founded in the 11th century AD, Polonnaruwa served as the seat of power for several
                                                    successive kingdoms, leaving behind a rich legacy of art, architecture, and culture.
                                                    Highlights of the archaeological site include the Royal Palace complex, with its imposing
                                                    stone foundations and ornate carvings, and the Gal Vihara, a series of colossal Buddha
                                                    statues carved into a single granite rock face. Visitors can also explore the sacred
                                                    Quadrangle, with its intricately carved shrines and soaring dagobas, as well as the
                                                    picturesque Tivanka Image House, adorned with vibrant frescoes depicting scenes from
                                                    Buddhist scripture. Polonnaruwa's evocative ruins and timeless beauty make it a must
                                                    visit destination for history enthusiasts and cultural travellers seeking to uncover the
                                                    secrets of Sri Lanka's storied past.  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-5" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Galle Fort, a colonial masterpiece nestled along the southern coast of Sri Lanka,
                                                    transports visitors to a bygone era of European maritime conquests and trade. This
                                                    UNESCO-listed site boasts well-preserved ramparts, cobblestone streets, and charming
                                                    colonial-era buildings, offering a glimpse into the island's multicultural heritage and
                                                    maritime history. Originally built by the Portuguese in the 16th century and later fortified
                                                    by the Dutch, Galle Fort is a testament to Sri Lanka's strategic importance as a trading
                                                    hub in the Indian Ocean. Visitors can wander through the narrow streets lined with quaint
                                                    cafes, boutique shops, and art galleries, soaking in the atmosphere of a bygone era.
                                                    Highlights of the fort include the iconic Dutch Reformed Church, with its elegant gabled
                                                    facade and historic gravestones, and the imposing Clock Tower, a symbol of colonial
                                                    authority and civic pride. Galle Fort's unique blend of European architecture, tropical
                                                    charm, and cultural diversity makes it a popular destination for history buffs, architecture
                                                    enthusiasts, and leisure travellers alike.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-6" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Anuradhapura, one of the oldest continuously inhabited cities in the world, is a UNESCO
                                                    World Heritage Site renowned for its ancient stupas, monasteries, and sacred bodhi
                                                    trees. This sprawling archaeological complex, dotted with towering dagobas and serene
                                                    lakes, serves as a spiritual pilgrimage site and a testament to Sri Lanka's Buddhist
                                                    heritage. Founded in the 4th century BCE, Anuradhapura was the capital of several
                                                    successive kingdoms and a centre of Buddhist learning and culture. Highlights of the
                                                    archaeological site include the Sri Maha Bodhi, a sacred fig tree believed to be the oldest
                                                    documented tree in the world and revered as a living symbol of enlightenment. Visitors
                                                    can also explore the majestic Ruwanwelisaya Stupa, the colossal Abhayagiri Monastery,
                                                    and the serene Jetavanarama Dagoba, among other ancient monuments and ruins.
                                                    Anuradhapura's sacred sites and ancient relics offer a profound spiritual experience and
                                                    a fascinating glimpse into Sri Lanka's rich cultural heritage and religious traditions. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-7" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Adam's Peak, revered by multiple religions for centuries, is a sacred pilgrimage site
                                                    located in the central highlands of Sri Lanka. The distinctive conical peak, rising to a
                                                    height of 2,243 meters, offers breathtaking views of the surrounding landscape and a
                                                    spiritual journey for pilgrims ascending its ancient steps. Known locally as Sri Pada, or
                                                    "Sacred Footprint," the peak is believed to bear the imprint of the Buddha's foot, as well
                                                    as the footprint of Adam, according to Islamic tradition, and the mark of Lord Shiva,
                                                    according to Hindu mythology. Each year, thousands of pilgrims from diverse religious
                                                    backgrounds make the arduous trek to the summit, especially during the pilgrimage
                                                    season from December to May. The climb to the top is illuminated by flickering oil lamps
                                                    and punctuated by stops at ancient shrines and tea stalls, culminating in a mesmerizing
                                                    sunrise over the surrounding mountains. Adam's Peak is not only a physical challenge but
                                                    also a spiritual quest, offering pilgrims and visitors alike a profound sense of awe,
                                                    reverence, and spiritual fulfilment.  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-8" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Kandy, nestled amidst lush green hills in the heart of Sri Lanka, is a UNESCO-listed city
                                                    steeped in cultural heritage and religious significance. The sacred city is home to the
                                                    revered Temple of the Tooth Relic and the annual Esala Perahera, a grand procession
                                                    celebrating Buddhist traditions and cultural pageantry. The Temple of the Tooth, located
                                                    within the royal palace complex known as the Sri Dalada Maligawa, houses the sacred
                                                    tooth relic of the Buddha and is considered one of the holiest sites in Sri Lanka. The
                                                    annual Esala Perahera, held in July or August, features a spectacular procession of
                                                    elephants, drummers, dancers, and fire performers, culminating in a ceremonial parade
                                                    through the streets of Kandy. Visitors can also explore the tranquil grounds of the Royal
                                                    Botanic Gardens, with its colourful orchids, exotic palms, and serene lakes, as well as
                                                    the picturesque Kandy Lake, a popular spot for leisurely strolls and boat rides. Kandy's
                                                    rich cultural heritage, vibrant festivals, and scenic beauty make it a must-visit destination
                                                    for travellers to Sri Lanka. </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tab-9" class="tab-pane fade show p-0">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex h-100">
                                            <div class="d-flex flex-column justify-content-center text-start bg-secondary border-inner px-4 py-2">
                                                <span style={{ textAlign: 'center' }}>Ella, nestled amidst the misty hills of Sri Lanka's central highlands, is a charming village
                                                    renowned for its scenic beauty, cool climate, and laid-back atmosphere. Surrounded by
                                                    lush tea plantations, cascading waterfalls, and verdant valleys, Ella offers visitors a
                                                    peaceful retreat amidst nature's splendour. The village is a popular starting point for
                                                    hiking adventures, with trails leading to iconic landmarks such as Little Adam's Peak, Ella
                                                    Rock, and the stunning Nine Arch Bridge. Visitors can also explore the nearby tea estates,
                                                    learn about the process of tea production, and sample freshly brewed cups of Ceylon
                                                    tea. Ella's relaxed vibe, stunning scenery, and outdoor activities make it a favourite
                                                    destination for travellers seeking to unwind and reconnect with nature in the heart of Sri
                                                    Lanka's hill country.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Wildlife */}

            <div class="container-xxl py-6" id="wildlife">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="row img-twice position-relative h-100">
                                <div class="col-6">
                                    <img class="img-fluid rounded" src="img/home_12.webp" alt="" />
                                </div>
                                <div class="col-6 align-self-end">
                                    <img class="img-fluid rounded" src="img/dest_07.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="h-100">
                                <h1 class="display-6 mb-4">Wildlife in Sri Lanka: A Rich Tapestry of Biodiversity</h1>
                                <p>Sri Lanka, often referred to as the "Pearl of the Indian Ocean," is renowned for its stunning
                                    landscapes, rich cultural heritage, and abundant wildlife. Experience the unparalleled
                                    biodiversity of Sri Lanka's wildlife destinations. Yala National Park, renowned for its dense
                                    leopard population, offers thrilling safaris amidst diverse ecosystems and historical
                                    sites. Horton Plains National Park, a UNESCO World Heritage Site, captivates with its
                                    rolling grasslands, endemic wildlife, and the breathtaking World's End cliff. Sinharaja Forest Reserve, another UNESCO site, is a pristine tropical rainforest teeming with
                                    endemic species and lush vegetation, ideal for hiking and eco-tourism. These wildlife
                                    sanctuaries, with their stunning landscapes and rich biodiversity, highlight Sri Lanka's
                                    commitment to conservation and make it a premier destination for nature enthusiasts
                                    and adventure seekers.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-2 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: '600px' }}>
                        <h1 class="display-6 mb-2">Visit Places in Wildlife</h1>
                        <p class="text-center">Sri Lanka's wildlife destinations, including Yala National Park, Horton Plains National
                            Park, and Sinharaja Forest Reserve, offer visitors a glimpse into the island's rich
                            biodiversity and natural heritage. Whether spotting leopards in the wild, trekking through
                            misty mountains, or exploring pristine rainforests, these sanctuaries provide
                            unforgettable experiences for nature lovers and adventure seekers alike. With their
                            stunning landscapes, diverse ecosystems, and abundant wildlife, these wildlife
                            destinations underscore Sri Lanka's status as a premier destination for ecotourism and
                            wildlife conservation. </p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.1s">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Yala National Park
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Yala National Park, located on the southeastern coast of Sri Lanka, is the country's most
                                            famous and visited wildlife sanctuary. Spanning over 979 square kilometers, it is home to
                                            a wide variety of ecosystems, including dense forests, grasslands, wetlands, and sandy
                                            beaches. Yala is renowned for its dense leopard population, offering visitors a rare
                                            opportunity to spot these elusive big cats in their natural habitat. The park is also home
                                            to a diverse range of wildlife, including elephants, sloth bears, crocodiles, and a
                                            multitude of bird species. Visitors can explore the park on guided safaris, either by jeep
                                            or on foot, allowing them to witness the spectacular wildlife and breathtaking landscapes
                                            of Yala. Other highlights of the park include the ancient Sithulpawwa Rock Temple, the
                                            picturesque Magul Maha Viharaya, and the serene Kumbukkan Oya Reservoir. Yala
                                            National Park is a must-visit destination for wildlife enthusiasts and nature lovers,
                                            offering an unforgettable safari experience amidst the natural wonders of Sri Lanka.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.2s">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Horton Plains National Park
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Horton Plains National Park, nestled amidst mist-shrouded mountains in the central
                                            highlands of Sri Lanka, is a biodiversity hotspot teeming with endemic flora and fauna.
                                            This UNESCO World Heritage Site, with its rolling grasslands, dense forests, and dramatic
                                            waterfalls, offers visitors a chance to explore pristine wilderness and witness the awe
                                            inspiring beauty of nature. The park is home to a diverse range of wildlife, including
                                            sambar deer, purple-faced langurs, and elusive leopards, as well as over 20 species of
                                            endemic birds. The highlight of Horton Plains is World's End, a sheer cliff that plunges
                                            over 800 meters into the valley below, offering breathtaking views of the surrounding
                                            landscape. Visitors can also trek to Baker's Falls, a picturesque waterfall cascading
                                            amidst lush greenery, and explore the mystical landscape of the Plains of the Moon.
                                            Horton Plains National Park is a paradise for nature lovers and outdoor enthusiasts,
                                            offering hiking trails, birdwatching opportunities, and the chance to experience the
                                            pristine beauty of Sri Lanka's highlands.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Sinharaja Forest Reserve
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Sinharaja Forest Reserve, a UNESCO World Heritage Site nestled in the southwestern
                                            corner of Sri Lanka, is a pristine tropical rainforest teeming with endemic biodiversity.
                                            This ecological marvel, with its towering trees, cascading streams, and diverse wildlife,
                                            offers visitors a rare opportunity to immerse themselves in nature and witness the
                                            wonders of a UNESCO-listed biosphere reserve. Sinharaja is home to a staggering array
                                            of flora and fauna, including over 50% of Sri Lanka's endemic species, such as the purple
                                            faced langur, the Sri Lankan leopard, and the elusive red-faced malkoha. The reserve is
                                            crisscrossed by a network of hiking trails, allowing visitors to explore its dense vegetation,
                                            crystal-clear streams, and hidden waterfalls. Highlights of Sinharaja include the
                                            Sinhagala Trail, offering panoramic views of the forest canopy, and the Senevirathna Trail,
                                            leading to the majestic Moulawella waterfall. Sinharaja Forest Reserve is a haven for
                                            nature lovers and eco-tourists, offering the chance to experience the unspoiled beauty
                                            and biodiversity of Sri Lanka's tropical rainforests.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* South Coast */}

            <div class="container-xxl py-6" id="south">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="h-100">
                                <h1 class="display-6 mb-4">Exploring the Enchanting South Coast of Sri Lanka</h1>

                                <p>The enchanting South Coast of Sri Lanka beckons travellers with its pristine beaches,
                                    vibrant culture, and rich history, stretching from the bustling city of Colombo to the
                                    serene shores of Tangalle. Colombo, the commercial capital, blends modernity and
                                    tradition with its historic Fort district, lively Pettah Market, and cultural landmarks like the
                                    National Museum. Bentota, a tropical paradise, offers palm-fringed beaches and water
                                    sports, along with tranquil river cruises through mangrove forests. Galle, a UNESCOlisted colonial gem, charms visitors with its cobblestone streets and well-preserved
                                    architecture. Mirissa provides a tranquil retreat with its crescent-shaped bay, ideal for
                                    whale watching and beachside relaxation. Unawatuna is famed for its golden sands,
                                    coral reefs, and the secluded Jungle Beach, perfect for snorkelling and diving. Tangalle, a
                                    hidden gem at the southern tip, features pristine beaches and the unique experience of
                                    witnessing sea turtles nesting. Each destination along the South Coast offers its own
                                    unique allure, making it a must-visit region for an unforgettable tropical getaway.
                                </p>

                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="row img-twice position-relative h-100">
                                <div class="col-6">
                                    <img class="img-fluid rounded" src="img/project-7.jpg" alt="" />
                                </div>
                                <div class="col-6 align-self-end">
                                    <img class="img-fluid rounded" src="img/project-8.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto mb-2 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: '600px' }}>
                        <h1 class="display-6 mb-2">Visit Places in South Coast</h1>
                        <p class="text-center">The South Coast of Sri Lanka offers a wealth of enchanting tourist destinations, from
                            bustling cities to tranquil beach towns, each with its own unique charm and allure.
                            Whether exploring historic forts in Galle, lounging on palm-fringed beaches in Mirissa, or
                            witnessing sea turtles nesting in Tangalle, visitors are sure to be captivated by the beauty
                            and diversity of this picturesque region. With its stunning landscapes, vibrant culture,
                            and warm hospitality, the South Coast of Sri Lanka is a must-visit destination for
                            travellers seeking an unforgettable tropical getaway.</p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.1s">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                            Colombo
                                        </button>
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            As the commercial capital of Sri Lanka, Colombo serves as the gateway to the island's
                                            south coast. This vibrant city offers a blend of modernity and tradition, with bustling
                                            markets, colonial-era architecture, and serene temples. Visitors can explore the historic
                                            Fort district, home to landmarks such as the Old Parliament Building and the Dutch
                                            Hospital Shopping Precinct. The vibrant Pettah Market is a treasure trove of sights,
                                            sounds, and flavours, while the Galle Face Green provides a picturesque setting for
                                            leisurely strolls along the waterfront. Colombo also boasts cultural attractions such as
                                            the National Museum and the Gangaramaya Temple, offering insights into Sri Lanka's rich
                                            heritage and diverse religious traditions.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.2s">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Bentota
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Located along the southwest coast of Sri Lanka, Bentota is a tropical paradise renowned
                                            for its pristine beaches, azure waters, and water sports opportunities. The town's palm
                                            fringed coastline offers a range of activities, including swimming, snorkelling, diving, and
                                            jet skiing, as well as leisurely walks along the soft sandy shores. Bentota is also home to
                                            the Bentota River, a tranquil waterway ideal for boat cruises, kayaking, and wildlife
                                            spotting. Visitors can explore the surrounding mangrove forests, spot exotic bird species,
                                            and even embark on a thrilling safari to observe crocodiles in their natural habitat.
                                            Bentota is also known for its luxury resorts and wellness retreats, offering travellers the
                                            chance to relax, rejuvenate, and indulge in pampering spa treatments amidst
                                            breathtaking coastal scenery. Whether seeking adventure or relaxation, Bentota's idyllic
                                            beaches and laid-back ambiance make it a perfect destination for sun-seekers and water
                                            sports enthusiasts alike.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                                    <h2 class="accordion-header" id="headingSix">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                           Galle
                                        </button>
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Galle, a colonial masterpiece nestled along the southern coast of Sri Lanka, transports
                                            visitors to a bygone era of European maritime conquests and trade. This UNESCO-listed
                                            site boasts well-preserved ramparts, cobblestone streets, and charming colonial-era
                                            buildings, offering a glimpse into the island's multicultural heritage and maritime history.
                                            Originally built by the Portuguese in the 16th century and later fortified by the Dutch, Galle
                                            Fort is a testament to Sri Lanka's strategic importance as a trading hub in the Indian
                                            Ocean. Visitors can wander through the narrow streets lined with quaint cafes, boutique
                                            shops, and art galleries, soaking in the atmosphere of a bygone era. Highlights of the fort
                                            include the iconic Dutch Reformed Church, with its elegant gabled facade and historic
                                            gravestones, and the imposing Clock Tower, a symbol of colonial authority and civic
                                            pride. Galle Fort's unique blend of European architecture, tropical charm, and cultural
                                            diversity makes it a popular destination for history buffs, architecture enthusiasts, and
                                            leisure travellers alike.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                                    <h2 class="accordion-header" id="headingSeven">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                           Mirissa
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Mirissa, located along the southern coast of Sri Lanka, is a tranquil beach town renowned
                                            for its pristine beaches, turquoise waters, and laid-back ambiance. The town's crescent
                                            shaped bay offers a picturesque setting for swimming, sunbathing, and snorkelling, while
                                            its palm-fringed shoreline is dotted with cozy beachfront cafes and seafood restaurants.
                                            Mirissa is also a popular whale watching destination, with boat tours offering the chance
                                            to spot majestic blue whales, playful dolphins, and other marine creatures in their natural
                                            habitat. Visitors can also explore nearby attractions such as the historic Paravi Duwa
                                            Temple and the serene coconut groves of Polhena Beach. With its relaxed vibe, stunning
                                            scenery, and array of activities, Mirissa is the perfect destination for travellers seeking to
                                            unwind and reconnect with nature amidst the beauty of Sri Lanka's south coast.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                                    <h2 class="accordion-header" id="headingEight">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                           Unawatuna
                                        </button>
                                    </h2>
                                    <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Unawatuna, located just a short drive from Galle, is a picturesque coastal town renowned
                                            for its golden sands, swaying palm trees, and vibrant coral reefs. The crescent-shaped
                                            bay offers calm and clear waters ideal for swimming, snorkelling, and diving, with
                                            colourful marine life and coral gardens just a short swim from the shore. Unawatuna is
                                            also home to the iconic Jungle Beach, a secluded cove nestled amidst lush greenery and
                                            towering cliffs, offering a tranquil retreat away from the hustle and bustle of the town.
                                            Visitors can explore the historic Japanese Peace Pagoda, take a leisurely stroll along the
                                            palm-fringed coastline, or indulge in fresh seafood and tropical cocktails at one of the
                                            beachfront restaurants. With its laid-back atmosphere, stunning beaches, and array of
                                            water sports activities, Unawatuna is a must-visit destination for beach lovers and
                                            adventure seekers alike.
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item wow fadeInUp" data-wow-delay="0.3s">
                                    <h2 class="accordion-header" id="headingNine">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                           Tangalle
                                        </button>
                                    </h2>
                                    <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            Tangalle, located at the southernmost tip of Sri Lanka, is a hidden gem known for its
                                            pristine beaches, tranquil bays, and scenic landscapes. The town's crescent-shaped bay
                                            offers calm and clear waters ideal for swimming, snorkelling, and kayaking, with
                                            picturesque palm-fringed beaches stretching as far as the eye can see. Tangalle is also
                                            home to the stunning Rekawa Beach, a nesting site for endangered sea turtles, where
                                            visitors can witness these majestic creatures laying their eggs under the cover of
                                            darkness. Nearby attractions include the historic Mulkirigala Rock Temple, perched atop
                                            a towering rock outcrop, and the serene Tangalle Lagoon, a haven for birdwatching and
                                            wildlife spotting. With its laid-back vibe, natural beauty, and array of activities, Tangalle
                                            offers travellers a tranquil retreat amidst the unspoiled splendour of Sri Lanka's south
                                            coast.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>









            {/* <div class="place-padding py-5" id="destination1">
                
            </div> */}

            {/* <div class="favourite-place place-padding py-5">
                <div class="container">

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section_title_div text-center position-relative pb-3 mb-0 mx-auto" style={{ maxwidth: '600px' }}>
                                <h5 class="fw-bold text-primary text-uppercase">FEATURED TOURS DESTINATIONS</h5>
                                <h1 class="mb-0">It's a Big World Out There, Go Explore</h1>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour1" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour2" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour3" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour4" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour5" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 py-3">
                            <div class="single-place mb-30">
                                <div class="place-img">
                                    <img src="img/about5.jpg" alt="tour6" />
                                </div>
                                <div class="place-cap">
                                    <div class="place-cap-top">
                                        <span><i class="flaticon-plane"></i><span>Aura Helicopter</span> </span>
                                        <h3><a href="#">Nuwara Eliya To Welisara</a></h3>
                                        <p class="dolor">$100 <span>/ Per Person (One Way)</span></p>
                                    </div>
                                    <div class="place-cap-bottom">
                                        <ul>
                                            <li><i class="far fa-clock"></i>5 Hours</li>
                                            <li><i class="fas fa-map-marker-alt"></i>Nuwara Eliya</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <br /><br /><br /> */}

            {/* <div class="video-area video-bg pt-200 pb-200 " style={{ backgroundImage: 'url(img/video-bg.jpg)' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="video-caption text-center">
                                <div class="video-icon">
                                    <a class="popup-video" data-bs-target="#videoModal" data-bs-toggle="modal" href="https://www.youtube.com/embed/MjDrlJZ6-Bs" tabindex="0"><i class="fas fa-play"></i></a>
                                </div>

                                <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content rounded-0">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Aura Airlines</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">

                                                <div class="ratio ratio-16x9">
                                                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/MjDrlJZ6-Bs" id="video" title="highlight-video" allowfullscreen allowscriptaccess="always"
                                                        allow="autoplay"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p class="pera1">Love where you're going in the perfect time</p>
                                <p class="pera2">Aura Airlines is a Online</p>
                                <p class="pera3"> Airlines Booking Platform</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="place-padding py-5" id="destination2">
                
                </div> */}


        </>
    )
}

export default Destination_page