import React from 'react'
import QRCode from 'qrcode.react';
import './profile.css';

const Profile_two = () => {

    const profileUrl = 'https://auralanka.com/';

  return (
    <>

          <div class="container-fluid page-header_profile"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Profile</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Profile</h6>
              </div>
          </div>

          <div class="container-xxl py-0">
              <div class="container">
                  <div class="row g-5">
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                              <img
                                  class="position-absolute w-100 h-100"
                                  src="img/team/sample.webp"
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                       
                              />
                          </div>
                      </div>
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="h-100">
                              <div class="border-start border-5 border-primary ps-4 mb-5">
                                  <h6 class="text-body text-uppercase mb-2">Director</h6>
                                  <h1 class="display-6 mb-0">
                                        Chandani Samaraweera
                                  </h1>
                              </div>
                              <p>
                                Mrs. Chandani Samaraweera is a distinguished business leader, holding the visionary leadership that has been instrumental in fostering the growth and success of a diverse portfolio of business entities. With a proven track record of expertise in the business realm, Mrs. Chandani Samaraweera has consistently demonstrated her remarkable ability to manage and drive the growth of multiple companies. Her adept guidance and strategic acumen have played a pivotal role in establishing the Aura Lanka Group as a prominent force in various industries.
                              </p>
                              <p>
                                Mrs. Chandani Samaraweera's exceptional leadership, coupled with her unwavering commitment to excellence, has been central in steering these businesses towards success. Her dedication to innovation and her profound understanding of the business landscape underscores her role as a dynamic and influential figure in the corporate world. As a visionary entrepreneur, Mrs. Chandani Samaraweera continues to make significant contributions to the growth and prosperity of the Aura Lanka Group of Companies, further solidifying her reputation as a leader in business and entrepreneurship.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
    </>
  )
}

export default Profile_two