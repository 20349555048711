import React from 'react'
import QRCode from 'qrcode.react';
import './profile.css';

const Profile_three = () => {

    const profileUrl = 'https://auralanka.com/';

  return (
    <>

          <div class="container-fluid page-header_profile"><br /><br /><br />
              <h1 class="display-3 text-uppercase text-white mb-3">Profile</h1>
              <div class="d-inline-flex text-white">
                  <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                  <h6 class="text-body m-0 px-3">/</h6>
                  <h6 class="text-uppercase text-body m-0">Profile</h6>
              </div>
          </div>

          <div class="container-xxl py-0">
              <div class="container">
                  <div class="row g-5">
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                              <img
                                  class="position-absolute w-100 h-100"
                                  src="img/team/sample.webp"
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                       
                              />
                          </div>
                      </div>
                      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="h-100">
                              <div class="border-start border-5 border-primary ps-4 mb-5">
                                  <h6 class="text-body text-uppercase mb-2">Accountable Manager / CEO</h6>
                                  <h1 class="display-6 mb-0">
                                        Isuru Mannapperuma
                                  </h1>
                              </div>
                              <p>
                                Mr. Isuru Mannapperuma is a true business professional with a remarkable career that spans leadership roles in multinational companies across Sri Lanka and overseas. His educational qualifications from Australia underscore his commitment to excellence on an international level.
                              </p>
                              <p>
                                Mr. Isuru Mannapperuma's expertise extends across the spectrum of business functionality. He is well-versed in marketing strategies that drive success, efficient logistics management, financial acumen that ensures fiscal responsibility, adept relationship management, and the intricacies of international business development.
                              </p>
                              <p>
                                His experience and skills make him invaluable any business environment, and his leadership roles reflect a commitment to steering organizations towards success.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
    </>
  )
}

export default Profile_three