import React from 'react'
import './About.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import Axios from 'axios';

const Aboutus = () => {

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behaviour: "smooth"});
    }

    return (
        <>
            <div class="container-fluid page-header_about"><br /><br /><br />
                <h1 class="display-3 text-uppercase text-white mb-3">About Us</h1>
                <div class="d-inline-flex text-white">
                    <h6 class="text-uppercase m-0"><a class="text-white" href="/">HOME</a></h6>
                    <h6 class="text-body m-0 px-3">/</h6>
                    <h6 class="text-uppercase text-body m-0">About</h6>
                </div>
            </div>

            {/* Aura Airlines (Private) Limited */}

            <div class="container-fluid py-3">
                <div class="container pb-3">
                    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="display-6">Aura Airlines (Private) Limited</h1>
                    </div>
                    <div class="row justify-content-center pt-3">
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Aura Airlines, founded in 2020, emerged with a vision to address the evolving needs of the commercial aviation sector on both local and global scales. Our commitment to excellence and innovation led us to introduce Aura Airlines' Helicopters in 2023, equipped with cutting-edge technology to deliver unparalleled services. Operating along Sri Lanka's popular air routes, our helicopters cater to a diverse range of needs, including passenger transport, cargo logistics, and bespoke charter flights.</p>
                        </div>
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Experience the unmatched convenience and luxury of traveling with Aura Airlines, the premier private helicopter charter service in Sri Lanka. Our fleet comprises state-of-the-art AIRBUS Helicopters, ensuring safety, comfort, and efficiency at every altitude. Nestled within our sophisticated service facility and hangar located in Ratmalana, we uphold the highest standards of maintenance and operational readiness.</p>
                        </div>
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Driven by a passionate team of pilots and maintenance personnel with extensive backgrounds in both civil and military aviation, Aura Airlines is poised to deliver unparalleled services across Sri Lanka. From corporate travel to leisure escapes to medical emergencies, we are dedicated to meeting and exceeding the diverse needs of our clientele with professionalism and precision. Discover the epitome of aviation excellence with Aura Airlines.</p>
                        </div>
                    </div>

                </div>
            </div>

            {/* End Aura Airlines (Private) Limited */}

            {/* Our Fleet */}

            <div class="container-fluid">
                <div class="container pb-3">
                    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 class="display-6">Our Fleet</h1>
                    </div>
                    <div class="row justify-content-center pt-3">
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Aura Airlines prides itself on being at the forefront of aviation technology, ensuring that our fleet incorporates the latest advancements in the industry. Currently, we offer an array of tour and charter services to diverse destinations across the country, facilitated by our state-of-the-art AIRBUS EC 130 B4 and AIRBUS H120 B Helicopters.</p>
                        </div>
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Our primary objective is to prioritize convenience and safety for our passengers, whether they are attending sporting events, business meetings, or engaging in aerial photography. With our AIRBUS fleet, we guarantee reliability and efficiency in transportation solutions.</p>
                        </div>
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Aura Airlines boasts a versatile fleet comprising the AIRBUS H130 and AIRBUS H120 multi-role helicopters, renowned for their superior performance, maneuverability, and experienced crew. These helicopters are tailored to meet various technical needs and services, ensuring optimal performance across a spectrum of missions.</p>
                        </div>
                        <div class="col-lg-10 text-center">
                            <p class="text-center">Discover unparalleled comfort, safety, and reliability with Aura Airlines and our esteemed fleet of AIRBUS helicopters.</p>
                        </div>
                    </div>

                    <div class="container-xxl bg-light my-2 py-4">
                        <div class="container py-1">
                            <div class="row g-4 justify-content-center">
                                <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                                {/* <small>Helicopter</small> */}
                                            </div>
                                            <h4 class="mb-3">Airbus Helicopter H130</h4>
                                            {/* <a class="text-uppercase" href="/Helicopter_one">Read More <i class="bi bi-arrow-right"></i></a> */}
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="Airlines/auraairline6.jpg" alt="" />
                                            <div class="causes-overlay">
                                                <Link onClick={scrollToTop} class="btn btn-outline-primary" to="/Helicopter">
                                                    Read More
                                                    <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                        <i class="fa fa-arrow-right"></i>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div class="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                                        <div class="text-center p-4 pt-0">
                                            <div class="d-inline-block text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                                                {/* <small>Helicopter</small> */}
                                            </div>
                                            <h4 class="mb-3">Airbus Helicopter H120</h4>
                                            {/* <a class="text-uppercase" href="/Helicopter_two">Read More <i class="bi bi-arrow-right"></i></a> */}
                                        </div>
                                        <div class="position-relative mt-auto">
                                            <img class="img-fluid" src="Airlines/auraairline3.jpg" alt="" />
                                            <div class="causes-overlay">
                                                <Link onClick={scrollToTop} class="btn btn-outline-primary" to="/Helicopter">
                                                    Read More
                                                    <div class="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                                                        <i class="fa fa-arrow-right"></i>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* End Our Fleet */}

            {/* <!-- Team Start --> */}

            <div class="container-xxl py-0">
                <div class="container">
                    <div class="text-center mx-auto wow animated slideInUp" data-wow-delay="0.6s" style={{ maxWidth: '500px' }}>
                        {/* <div class="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Team Members</div> */}
                        <h1 class="display-6 mb-4">Our Professional Expertise</h1>
                    </div>

                    {/* <div class="row g-4">

                        <OwlCarousel
                            className="team-carousel"
                            autoplay
                            smartSpeed={1500}
                            margin={30}
                            dots={false}
                            loop
                            nav
                            navText={[
                                '<i class="fa fa-angle-left" aria-hidden="true"></i>',
                                '<i class="fa fa-angle-right" aria-hidden="true"></i>',
                            ]}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                576: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                992: {
                                    items: 3,
                                },
                            }}
                        >

                            <div class="team-item position-relative rounded overflow-hidden">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src="img/team/team-1.jpg" alt="aura_team_member" />
                                    <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                        <Link onClick={scrollToTop} class="btn btn-primary w-50 py-3" to="/Profile_one">About More</Link>
                                    </div>
                                </div>
                                <div class="team-text bg-light text-center p-4">
                                    <h5>Dr. Viranjith Thambugala</h5>
                                    <p class="text-primary">Chairman / Director</p>
                                    <a class="text-uppercase" href="/Profile_one">View Profile <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="team-item position-relative rounded overflow-hidden">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src="img/team/sample.webp" alt="aura_team_member" />
                                    <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                        <Link onClick={scrollToTop} class="btn btn-primary w-50 py-3" to="/Profile_two">About More</Link>
                                    </div>
                                </div>
                                <div class="team-text bg-light text-center p-4">
                                    <h5>Chandani Samaraweera</h5>
                                    <p class="text-primary">Director</p>
                                    <a class="text-uppercase" href="/Profile_two">View Profile <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="team-item position-relative rounded overflow-hidden">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src="img/team/sample.webp" alt="aura_team_member" />
                                    <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                        <Link onClick={scrollToTop} class="btn btn-primary w-50 py-3" to="/Profile_three">About More</Link>
                                    </div>
                                </div>
                                <div class="team-text bg-light text-center p-4">
                                    <h5>Isuru Mannapperuma</h5>
                                    <p class="text-primary">Accountable Manager / CEO</p>
                                    <a class="text-uppercase" href="/Profile_three">View Profile <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            <div class="team-item position-relative rounded overflow-hidden">
                                <div class="overflow-hidden">
                                    <img class="img-fluid" src="img/team/team-2.jpg" alt="aura_team_member" />
                                    <div class="team-overlay position-absolute start-0 top-0 w-100 h-100">
                                        <Link onClick={scrollToTop} class="btn btn-primary w-50 py-3" to="/Profile_four">About More</Link>
                                    </div>
                                </div>
                                <div class="team-text bg-light text-center p-4">
                                    <h5>Ruwan Nayanajith</h5>
                                    <p class="text-primary">Head of Administration, Sales & Finance</p>
                                    <a class="text-uppercase" href="/Profile_four">View Profile <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                        </OwlCarousel>

                    </div> */}

                </div>
            </div>

            {/* <!-- Team End --> */}

            <section id="team_member" class="team_member">
                <div class="container">

                    <div class="row">

                        <div class="col-lg-8">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Dr. Viranjith Thambugala</h4>
                                    <span style={{ color: '#fff' }}>Chairman / Director</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Dr. Viranjith Thambugala is a highly esteemed and influential...</p>
                                    <a class="text-uppercase" href="/Profile_one" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Chandani Samaraweera</h4>
                                    <span style={{ color: '#fff' }}>Director</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mrs. Chandani Samaraweera is a distinguished business leader...</p>
                                    <a class="text-uppercase" href="/Profile_two" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Isuru Mannapperuma</h4>
                                    <span style={{ color: '#fff' }}>Accountable Manager / CEO</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Isuru Mannapperuma is a true business professional with...</p>
                                    <a class="text-uppercase" href="/Profile_three" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Ruwan Nayanajith</h4>
                                    <span style={{ color: '#fff' }}>Head of Administration, Sales & Finance</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Coming from an aircraft engineering background, Mr. Ruwan Nayanajith...</p>
                                    <a class="text-uppercase" href="/Profile_four" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Capt. Thushara Salgado</h4>
                                    <span style={{ color: '#fff' }}>Chief Pilot & Manager Aviation Security</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Captain Thushara Salgado is a highly seasoned pilot in Sri Lanka...</p>
                                    <a class="text-uppercase" href="/Profile_three" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Capt. Dakshina Perera</h4>
                                    <span style={{ color: '#fff' }}>Pilot, Manager Flight Operations & Manager Flight Training</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>A retired Substantive Squadron Leader from the Sri Lanka Air Force...</p>
                                    <a class="text-uppercase" href="/Profile_four" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Lalith Dammasiri</h4>
                                    <span style={{ color: '#fff' }}>Manager Engineering</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Lalith Dammasiri is a highly accomplished Aircraft Engineer...</p>
                                    <a class="text-uppercase" href="/Profile_three" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Clarence Subasinghe</h4>
                                    <span style={{ color: '#fff' }}>Certifying Engineer</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>A highly accomplished engineering professional with an extraordinary...</p>
                                    <a class="text-uppercase" href="/Profile_four" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Isuru Dharmarathna</h4>
                                    <span style={{ color: '#fff' }}>Deputy Engineering Manager</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Isuru Dharmarathna embarked on his aviation career in 2016...</p>
                                    <a class="text-uppercase" href="/Profile_three" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Roshan Corea</h4>
                                    <span style={{ color: '#fff' }}>Flight Dispatcher</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Roshan Corea is a highly experienced professional in the aviation industry...</p>
                                    <a class="text-uppercase" href="/Profile_four" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to right, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                                <div class="member-info" style={{ marginLeft: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Mangala Senavirathna</h4>
                                    <span style={{ color: '#fff' }}>Aircraft Technician</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Mangala Senavirathna is a seasoned...</p>
                                    <a class="text-uppercase" href="/Profile_three" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-4 mt-4 mt-lg-0">
                        </div>

                        <div class="col-lg-8 mt-4">
                            <div class="member d-flex align-items-start" style={{ background: 'linear-gradient(to left, #243e8d, #f8f9fa)', borderRadius: '20px', padding: '20px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>

                                <div class="member-info-sec" style={{ marginRight: '20px' }}>
                                    <h4 style={{ color: '#fff' }}>Sampath Dilantha</h4>
                                    <span style={{ color: '#fff' }}>Aircraft Technician</span>
                                    <p class="mb-1" style={{ color: '#fff' }}>Mr. Sampath Dilantha, a seasoned aviation expert...</p>
                                    <a class="text-uppercase" href="/Profile_four" style={{ color: '#0d6efd' }}>Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                                <div class="pic" style={{ borderRadius: '50%', overflow: 'hidden', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                                    <img src="img/team/sample.webp" class="img-fluid" alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>


            {/* Know About Aura Airlines */}

            {/* <section class="container-fluid py-3 wow animated fadeInUp" data-wow-delay="0.6s">
                <div class="container">
                    <div class="row g-5">

                        <div class="col-md-6 d-flex">
                            <div class="img img-video d-flex align-self-stretch align-items-center justify-content-center mb-4 mb-sm-0" style={{ backgroundImage: 'url(img/about4.jpg)' }}>
                                <a data-bs-toggle="modal" data-src="https://www.youtube.com/embed/MjDrlJZ6-Bs" data-bs-target="#videoModal" class="icon-video popup-vimeo d-flex justify-content-center align-items-center">
                                    <span class="fa fa-play"></span>
                                </a>
                            </div>
                        </div>

                        <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content rounded-0">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Aura Airlines</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">

                                        <div class="ratio ratio-16x9">
                                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/MjDrlJZ6-Bs" id="video" title="highlight-video" allowfullscreen allowscriptaccess="always"
                                                allow="autoplay"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 pl-md-5">
                            <div class="row justify-content-start py-5">
                                <div class="col-md-12 heading-section ftco-animate">
                                    <span class="subheading">Who We Are</span>
                                    <h2 class="mb-4">Get to Know Us</h2>
                                    <p>Aura Airlines is a pioneering entity in the Sri Lankan airline
                                        industry, established in 2018 with a singular dedication to
                                        enhancing airline services for the greater community's benefit. As
                                        a distinctive platform, we provide Private and Business Airline
                                        services that cater to a wide spectrum of customer needs,
                                        encompassing leisure, corporate, and VIP transport solutions,
                                        serving both the domestic and international markets.
                                    </p>
                                    <div class="tabulation-2 mt-4">
                                        <ul class="nav nav-pills nav-fill d-md-flex d-block">
                                            <li class="nav-item mb-md-0 mb-2">
                                                <a class="nav-link active py-2" data-toggle="tab" href="#home1">Our Mission</a>
                                            </li>
                                            <li class="nav-item px-lg-2 mb-md-0 mb-2">
                                                <a class="nav-link py-2" data-toggle="tab" href="#home2">Our Vision</a>
                                            </li>

                                        </ul>
                                        <div class="tab-content rounded mt-2">
                                            <div class="tab-pane container p-0 active" id="home1">
                                                <p>At Aura Airlines, we are unwavering in our commitment to
                                                    maintaining impeccable safety records, constantly striving to meet
                                                    the most rigorous professional and regulatory standards within the
                                                    airline industry. Our dedication is rooted in not only providing
                                                    exceptional service but also in achieving utmost customer
                                                    satisfaction. We endeavor to enhance local opportunities, uphold
                                                    social responsibility, contribute to economic prosperity, and
                                                    safeguard environmental well-being for all stakeholders. This
                                                    commitment drives us to adapt and diversify to meet the evolving
                                                    needs of today's airline business requirements, ensuring a
                                                    sustainable and prosperous future for all.</p>
                                            </div>
                                            <div class="tab-pane container p-0 fade" id="home2">
                                                <p>Our aspiration is to ascend to the pinnacle of the business and leisure
                                                    airline industry, establishing ourselves as the foremost leaders in
                                                    conquering global skies.</p>
                                                <p>We aim to be the partner of choice, dedicated to delivering products and
                                                    services that align with and bolster our national objectives. Our
                                                    unwavering commitment extends to the betterment of our environment
                                                    and the communities in which we operate, as we strive to uplift both for a
                                                    brighter and more sustainable future.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Who We Are */}

            {/* <section>
                <div class="container-xxl py-5">
                    <div class="container">
                        <div class="row g-5">
                            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                <span class="subheading">What are our core values</span>
                                <h2 class="mb-4">We are a team with excellent service and promised core values</h2>

                                <h6 class="mb-3">
                                    <i class="far fa-check-circle text-primary me-3"></i>Customer first and always right: Customer is always our priority.
                                </h6>
                                <h6 class="mb-3">
                                    <i class="far fa-check-circle text-primary me-3"></i>Consistency of the product: Succeed to ensure highest quality product delivery and maintain the desired standard. Commitment to treat Customer needs as priority.
                                </h6>
                                <h6 class="mb-3">
                                    <i class="far fa-check-circle text-primary me-3"></i>Teamwork: Work as a team, identify each team member and get involved towards success by competence, commitment, and determination.
                                </h6>
                                <h6 class="mb-3">
                                    <i class="far fa-check-circle text-primary me-3"></i>Achievement: We thrive for better ways to pursue our customer and employee satisfaction and achieving organisational and personal goals for a secure tomorrow.
                                </h6>
                                <h6 class="mb-3">
                                    <i class="far fa-check-circle text-primary me-3"></i>Excellence: Commitment to do best in all what we do and strive for professional excellence by exceeding expectations to buy out the organisational excellence.
                                </h6>

                            </div>
                            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div class="img-border">
                                    <img class="img-fluid" src="img/31226-min.jpg" alt="who we are" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Our Services */}

            {/* <div class="container-xxl py-3">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div class="section-title">
                                <span class="subheading">What are our core values</span>
                                <h1 class="display-6">We are a team with excellent service and promised core values</h1>
                            </div>
                            <div class="row g-3 mb-4">
                                <div class="col-sm-4">
                                    <img class="img-fluid bg-white w-100 wow animated zoomIn" data-wow-delay="0.6s" src="img/whoweare/who1.jpg" alt="" />
                                </div>
                                <div class="col-sm-8">
                                    <h5>Customer First</h5>
                                    <p class="mb-0">Our customers are our top priority, and their satisfaction is our commitment</p>
                                </div>
                            </div>
                            <div class="border-top mb-4"></div>
                            <div class="row g-3">
                                <div class="col-sm-8">
                                    <h5>Product Consistency</h5>
                                    <p class="mb-0">We consistently deliver high-quality products while upholding stringent standards and prioritizing customer needs</p>
                                </div>
                                <div class="col-sm-4">
                                    <img class="img-fluid bg-white w-100 wow animated zoomIn" data-wow-delay="0.6s" src="img/whoweare/who3.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div class="row g-3 mb-4">
                                <div class="col-sm-4">
                                    <img class="img-fluid bg-white w-100 wow animated zoomIn" data-wow-delay="0.6s" src="img/whoweare/who2.jpg" alt="" />
                                </div>
                                <div class="col-sm-8">
                                    <h5>Teamwork</h5>
                                    <p class="mb-0">We operate as a cohesive unit, valuing each team member's contributions and working together with competence, commitment, and determination</p>
                                </div>
                            </div>
                            <div class="border-top mb-4"></div>
                            <div class="row g-3 mb-4">
                                <div class="col-sm-8">
                                    <h5>Achievement</h5>
                                    <p class="mb-0">We continuously seek innovative ways to enhance customer and employee satisfaction while achieving both organizational and personal goals, securing a better future</p>
                                </div>
                                <div class="col-sm-4">
                                    <img class="img-fluid bg-white w-100 wow animated zoomIn" data-wow-delay="0.6s" src="img/whoweare/who4.jpg" alt="" />
                                </div>
                            </div>
                            <div class="border-top"></div>
                            <div class="row g-3 mb-4">
                                <div class="col-sm-4">
                                    <img class="img-fluid bg-white w-100 wow animated zoomIn" data-wow-delay="0.6s" src="img/whoweare/home_3.webp" alt="" />
                                </div>
                                <div class="col-sm-8">
                                    <h5>Excellence</h5>
                                    <p class="mb-0"> Our dedication to excellence drives us to perform at our best in all endeavors, striving for professional excellence that exceeds expectations and elevates organizational standards</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="container-xxl service py-4">
                <div class="container">
                    <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <span class="subheading">What are our core values</span>
                        <h1 class="display-6">We are a team with excellent service</h1>
                        <h1 class="display-6">and promised core values</h1>
                    </div>
                    <div class="row g-4 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="col-lg-4">
                            <div class="nav w-100 nav-pills me-4">
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4 active" data-bs-toggle="pill" data-bs-target="#tab-pane-1" type="button">
                                    <i class="fa fa-car-side fa-2x me-3"></i>
                                    <h4 class="m-0">Customer First</h4>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-2" type="button">
                                    <i class="fa fa-car fa-2x me-3"></i>
                                    <h4 class="m-0">Teamwork</h4>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-4" data-bs-toggle="pill" data-bs-target="#tab-pane-3" type="button">
                                    <i class="fa fa-cog fa-2x me-3"></i>
                                    <h4 class="m-0">Achievement</h4>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-0" data-bs-toggle="pill" data-bs-target="#tab-pane-4" type="button">
                                    <i class="fa fa-oil-can fa-2x me-3"></i>
                                    <h4 class="m-0">Excellence</h4>
                                </button>
                                <button class="nav-link w-100 d-flex align-items-center text-start p-4 mb-0" data-bs-toggle="pill" data-bs-target="#tab-pane-5" type="button">
                                    <i class="fa fa-oil-can fa-2x me-3"></i>
                                    <h4 class="m-0">Product Consistency</h4>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="tab-content w-100">
                                <div class="tab-pane fade show active" id="tab-pane-1">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/whoweare/who6.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-3">Customer First</h3>
                                            <p class="mb-4">Our customers are our top priority, and their satisfaction is our commitment</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-2">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/whoweare/who2.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-3">Teamwork</h3>
                                            <p class="mb-4">We operate as a cohesive unit, valuing each team member's contributions and working together with competence, commitment, and determination</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-3">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/whoweare/who4.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-3">Achievement</h3>
                                            <p class="mb-4">We continuously seek innovative ways to enhance customer and employee satisfaction while achieving both organizational and personal goals, securing a better future</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-4">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/whoweare/home_3.webp"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-3">Excellence</h3>
                                            <p class="mb-4">Our dedication to excellence drives us to perform at our best in all endeavors, striving for professional excellence that exceeds expectations and elevates organizational standards</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-5">
                                    <div class="row g-4">
                                        <div class="col-md-6" style={{ minHeight: '350px' }}>
                                            <div class="position-relative h-100">
                                                <img class="position-absolute img-fluid w-100 h-100" src="img/whoweare/who3.jpg"
                                                    style={{ objectFit: 'cover' }} alt="" />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="mb-3">Product Consistency</h3>
                                            <p class="mb-4">We consistently deliver high-quality products while upholding stringent standards and prioritizing customer needs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <!-- WHAT ARE OUR CORE VALUES --> */}

            {/* <section>

                <div class="categories-area section-padding30 py-4 wow animated slideInUp" data-wow-delay="0.6s">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-tittle text-center position-relative pb-3 mb-0 mx-auto" style={{ maxWidth: '600px' }}>
                                    <h5 class="fw-bold text-primary text-uppercase">Our Services</h5>
                                    <h1 class="mb-0">What We Can Do For You</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-30">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Corporate VIP/VVIP client</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Special events-product launches</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Passenger charter</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-30">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Flower drops</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Medical evacuation</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Banner towing/ Flag displays</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-30">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Celebrations</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Wedding photography</a></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 wow animated zoomIn" data-wow-delay="0.6s">
                                <div class="single-cat text-center mb-50">
                                    <div class="cat-cap py-5">
                                        <h4><a href="services.html">Branding and advertising</a></h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <!-- WHAT ARE OUR CORE VALUES --> */}

            {/* Chairman Section */}

            {/* <section>
                <div class="container-fluid p-0 my-3 wow animated slideInUp" data-wow-delay="0.6s">
                    <div class="row g-0">
                        <div class="col-lg-6" style={{ minWidth: '500px' }}>
                            <div class="position-relative h-100">
                                <img class="position-absolute w-100 h-100" src="img/aboutus.jpg" style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div class="col-lg-6 bg-dark-chairman p-5">
                            <div class="mb-5">
                                <h5 class="text-primary text-uppercase">Chairman And Founder</h5>
                                <h4 class="display-6 text-uppercase text-light mb-0">Dr. Viranjith Thambugala</h4>
                            </div>
                            <div class="owl-carousel testimonial-carousel">

                                <div class="testimonial-item">
                                    <p class="fs-5 fw-normal text-light mb-4">
                                        <i class="fa fa-quote-left text-primary me-3"></i>
                                        Experience the pinnacle of private air travel with Aura Airlines' private
                                        helicopter charter service. Step into an extraordinary journey where
                                        safety, comfort, privacy, and convenience take precedence. With a fleet of
                                        AIRBUS Helicopters, an AIRBUS approved service facility, and a dedicated
                                        hangar in Ratmalana, complemented by our highly experienced staff, your
                                        well-being is our paramount concern.
                                        <i class="fa fa-quote-right text-primary me-3"></i></p>
                                </div>

                                <div class="testimonial-item">
                                    <p class="fs-5 fw-normal text-light mb-4">
                                        <i class="fa fa-quote-left text-primary me-3"></i>
                                        When you choose to fly with us, you're choosing an unparalleled level of
                                        safety and luxury, allowing you to relax and enjoy every moment. We
                                        make every flight a personalized experience, ensuring safe, efficient, and
                                        reliable solutions that exceed expectations for all our stakeholders. Our
                                        mission is to provide top-notch, integrated utility aircraft services,
                                        contributing to infrastructure development and the protection of people
                                        5
                                        and property, all while maintaining the highest standards of safety and
                                        efficiency.
                                        <i class="fa fa-quote-right text-primary me-3"></i></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default Aboutus